@import 'src/styles/color';
@import 'src/styles/mixin';

.card {
  @include flex-row;

  padding: 24px 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: $white_BG;
  column-gap: 24px; 
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: $grey_main-BG-stroke;
  }
}
