@import '../../../../../styles/color.scss';
@import '../../../../../styles/mixin.scss';

.main{
    @include flex-column;
    gap: 16px;
}

.singleSection{
    @include flex-column;
    gap: 4px;
    background-color: $white_BG;
    border-radius: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.singleAction{
    padding: 8px 20px; 
}

.selectedAction{
    border-radius: 6px; 
    background-color: $blue_main-100;
}