@import 'src/styles/color';
@import 'src/styles/mixin';

.wrapper {
  @include flex-row;

  flex-wrap: wrap;
  margin-top: 12px;
  gap: 8px;

  button {
    color: $grey_secondary-text;
  }

  li {
    display: inline-block;

    &:not(:last-child)::after {
      margin-left: 8px;
      color: $grey_secondary-text;
      content: '•';
      letter-spacing: normal;
    }
  }
}