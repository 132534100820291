@import './constants';

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

@mixin sticky-base {
  @include flex-column;

  position: sticky;
  bottom: $STICKY_BUTTON_MARGIN_BOTTOM;
  width: $MOBILE_WIDTH;
}

.sticky-button-wrapper {
  @include sticky-base;

  bottom: $STICKY_BUTTON_POSITION_BOTTOM;
}

.fixed-modal-button {
  @include sticky-base;

  position: fixed;
  left: $MOBILE_STICKY_LEFT;
}

.two-fixed-buttons-modal {
  @include sticky-base;

  position: fixed;
  left: $MOBILE_STICKY_LEFT;
  background-color: $white_BG;
  gap: 10px;
}

.fixed-button-container {
  overflow: auto;
  height: calc(100% - $STICKY_BUTTON_SPACE);
}

.two-fixed-buttons-container {
  overflow: auto;
  height: calc(100% - 2 * $STICKY_BUTTON_SPACE - $STICKY_BUTTON_MARGIN_BOTTOM);
}

.inline {
  display: inline;
}

.semitransparent {
  opacity: 0.5;
}

.color-primary {
  color: $blue_main-500;
}

.color-disabled {
  color: $grey_caption-disabled-text;
}
