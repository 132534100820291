@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/typography';
@import 'src/styles/responsive';

.formRadio {
  @include bodySizeM;

  color: $black_main-text;
  gap: 20px;
}

.twoColumnFormRadio {
  display: grid;
  justify-content: start;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  justify-items: start;
}

.buttonRow {
  @include flex-column;

  gap: 12px;
}

