.newItemContainer {
  margin-top: 60px;
}

.newItemMainCategories {
  min-height: 100vh;
}

.draftFormHeader {
  gap: 12px;
}

.heading {
  display: flex;
  margin-bottom: 24px;
}