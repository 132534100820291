@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants';
@import 'src/styles/typography.scss';

.dropdown {
  position: relative;
}

.categoryButton {
  white-space: nowrap;
}

.categoryLevel {
  @include flex-column;
  gap: 12px;
  background-color: $white_BG;
}

.overlay {
  position: absolute;
  margin-top: 24px;
  left: calc(($BASE_WIDTH - 100vw) / 2 - $BASE_PADDING);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: $Z-IND-DROPDOWN;
  background-color: $black_main-text-50;
  overflow: auto;
}

$SIDE_PADDING: 24px;

.dropdownWrapper {
  position: absolute;
  top: 0;
  left: calc((100% - $BASE_WIDTH) / 2 - $SIDE_PADDING);
  width: calc($BASE_WIDTH + 2 * $SIDE_PADDING);
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);
  background-color: $white_BG;
  padding: 24px $SIDE_PADDING;
  border-radius: 0px 0px 16px 16px;
}

.parent {
  @include heading;
  @include flex-row;
  justify-content: space-between;
  align-items: center;
}

.parentBackBlock {
  @include flex-row-center;
  gap: 12px;
}

.buttonIcon {
  pointer-events: none;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .parent {
    margin-bottom: 4px;
  }

  .categoryLevel {
    gap: 0;
  }
}
