@import 'src/styles/mixin';
@import 'src/styles/constants';

$FULLSCREEN_SIDE_PADDING_MOBILE: 16px;

.image, .singleImage {
  max-width: 100%;
  max-height: calc(100vh - $OFFER-PAGE-FULLSCREEN_SLIDER_TOP_PADDING_MOBILE - $OFFER-PAGE-FULLSCREEN_SLIDER_BOTTOM_PADDING_MOBILE);
  margin: auto;
  margin-top: 0;
}

.overlay {
  background-color: $black_main-text;
}

.container {
  @include inner;

  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 12px 0;
}

.counterOverlay {
  bottom: 22px;
}

.buttonRow {
  display: grid;
  padding-right: $FULLSCREEN_SIDE_PADDING_MOBILE;
  padding-left: $FULLSCREEN_SIDE_PADDING_MOBILE;
  margin-bottom: 12px;
  color: $white_BG;
  gap: 20px;
  grid-template-columns: 1fr auto auto;

  .closeButton {
    grid-column: 1/2;
    grid-row: 1;
    justify-self: flex-start;
  }

  .likeButton {
    grid-column: 2/3;
    grid-row: 1;
  }

  .shareButton {
    grid-column: 3/4;
  }
}

.title {
  @include flex-row-center;

  margin-bottom: 28px;
  color: $white_BG;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .container {
    width: 100vw;
    padding: 20px 25px;
  }

  .overlay {
    background-color: rgba($black_main-text, 0.90);
  }

  .buttonRow {
    @include flex-row;

    justify-content: end;
    margin-bottom: 0;
    gap: 20px;
  }

  .image, .singleImage {
    max-width: calc(100% - 2 * $OFFER-PAGE-FULLSCREEN_SLIDER_CAROUSEL_BUTTON_SPACE);
    max-height: 100%;
  }

  .singleImage {
    max-height: calc(100% - $OFFER-PAGE-FULLSCREEN_SLIDER_TOP_PADDING - $OFFER-PAGE-FULLSCREEN_SLIDER_BOTTOM_PADDING);
  }
}
