@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

$imageWidth: 216px;
$imageWidthMobile: 140px;

.image {
  width: $imageWidthMobile;
  min-width: $imageWidthMobile;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.noPhoto {
  background-color: $placeholder-background;
}

.imagesNum {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: $white_BG_80;
  border-radius: 6px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .image {
    width: $imageWidth;
    min-width: $imageWidth;
  }
}