@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

// TODO: re-use the vars
$OFFER_CARD_GAP: 12px;
$OFFER_CARD_GAP_DESKTOP: 16px;

.main {
  @include flex-column;

  position: relative;
  max-width: 100%;
  row-gap: 4px;
  padding: $OFFER_CARD_GAP;
  padding-left: 0;
  overflow: hidden;
}

.description {
  @include text-overflow-ellipsis(2);

  color: $grey_caption-disabled-text;
}

.caption {
  @include text-overflow-ellipsis(1);

  color: $grey_caption-disabled-text;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .specs {
    padding: $OFFER_CARD_GAP_DESKTOP;
    padding-left: 0px;
  }
}
