@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main {
  @include flex-column;

  gap: 24px;
}

.message {
  @include flex-column;

  row-gap: 12px;
}

.prompts {
  @include flex-row;

  flex-wrap: wrap;
  gap: 4px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    display: grid;
    row-gap: 24px;
    grid-template-columns: repeat(7, 1fr);

    h6 {
      grid-column: 1 / 8;
    }
  }

  .message {
    @include flex-column;

    grid-column: 1 / 7;
    margin-right: 50px;
    row-gap: 24px;
  }

  .prompts {
    gap: 6px;
  }
}
