@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/responsive.scss';

$MESSENGER_LABEL_PADDING: 20px;
$MESSENGER_LABEL_PADDING_MOBILE: 24px;
$PADDING_SIDE: 16px;
$LABEL_HEIGHT: 55px;

.main {
  height: 100%;
  width: calc(100% + 2 * $BASE_PADDING);
  transform: translateX(-$BASE_PADDING);
  background-color: $white_BG;
}

.messengerLabel {
  display: flex;
  gap: 12px;
  padding: $MOBILE_TOP_PADDING $BASE_PADDING $MESSENGER_LABEL_PADDING_MOBILE;
  background-color: $grey_disabled-BG;
}

.messengerWrapper {
  @include flex-column;

  height: 100%;
  padding-bottom: 12px;
}

.messenger {
  display: grid;
  grid-template-columns: 3fr 6fr;

  height: $MESSENGER_WINDOW_HEIGHT;
  background-color: $white_BG;
  border-radius: 5px;
  overflow: hidden;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .messenger {
    overflow: unset;
  }

  .messengerLabel {
    padding: 0 0 $MESSENGER_LABEL_PADDING 0;
    background-color: inherit;
  }

  .main {
    width: 100%;
    transform: unset;
    height: 100%;

    display: grid;
    grid-template-columns: 10fr 3fr;
    gap: 40px;
    background-color: unset;
  }

  .showcaseBlock {
    margin-top: $MESSENGER_PADDING_TOP - $MESSENGER_LABEL_PADDING - $VERTICAL_SHOWCASE_TOP_PADDING;
  }

  .messengerWrapper {
    padding-top: $MESSENGER_PADDING_TOP - $MESSENGER_LABEL_PADDING - $LABEL_HEIGHT;
    padding-bottom: 0;
  }
}