@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$ICON_AND_GAP_WIDTH: 48px;

.main {
  justify-content: space-between;
  padding: 0 20px;
  column-gap: 16px;

  @include flex-row;

  span{
    text-align: left;
  }

  &:hover {
    border-radius: 8px;
    background-color: $grey_main-BG-stroke;
  }
}

.value {
  @include text-overflow-ellipsis(2);

  width: 100%;
  align-items: center;
  column-gap: 8px;
}

.disabled {
  &.label, .value {
    color: $grey_caption-disabled-text;
  }

  .main{
    &:hover{
      background-color: transparent;
    }
  }
}

.singleToggleLine {
  padding: 12px 0;
}

.editBtnMobile {
  height: fit-content;
  align-self: center;
}

.content {
  @include flex-row;

  width: 100%;
  justify-content: space-between;
  column-gap: 16px;
}

.label {
  @include flex-row;

  margin-bottom: 4px;
}

.hasIcon {
  @include flex-row;
}

.settingLineCurrentInfo {
  display: block;
  width: calc(100% - $ICON_AND_GAP_WIDTH);
}

.selected{
  @include flex-column;

  border-radius: 8px;
  background-color: $grey_main-BG-stroke;
}

.sumbitCancelButtons{
  display: flex;
  padding-top: 16px;
  gap: 12px;
}

.mobileToggleLine {
  padding: 12px 0;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .editButton {
    grid-column: 8 / 10;
  }

  .main{
    padding: 12px 28px;

    .editButton{
      display: flex;
      justify-content: flex-end;
     }
  }

  .content {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(9, 1fr);
  }

  .value {
    width: 100%;
  }

  .settingLineCurrentInfo {
    @include flex-row;

    width: 100%;
    align-items: center;
    justify-content: space-between;
    grid-column: 1 / 8;

    &.label, .value {
      flex: 0 0 50%;
    }
  }

  .fieldTitle {
    grid-column: 1 / 4;
  }

  .formFieldsWithButtons{
    grid-column: 4 / 9;
  }
}