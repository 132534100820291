@import './mixin';
@import './color';

@mixin mobile-paddings {
  padding: $MOBILE_TOP_PADDING $BASE_PADDING 0;
}

.mobile-paddings {
  @include mobile-paddings;
}

.mobile-hide {
  display: none;

  @media screen and (min-width: $DESKTOP-BREAKPOINT) {
    display: initial;
  }
}

.desktop-hide {
  @media screen and (min-width: $DESKTOP-BREAKPOINT) {
    display: none;
  }
}