@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main {
  margin: 24px 0 0;
}

.title {
  margin-bottom: 16px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    margin-top: 36px;
  }
  
  .title {
    margin-bottom: 24px;
  }
}