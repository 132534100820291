@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.actionBar {
  @include flex-row-center;

  position: sticky;
  z-index: $Z-IND-STICKY;
  top: 0;
  justify-content: space-between;
  padding: 12px 0;
  background-color: $grey_disabled-BG;
  gap: 16px;
}

.rightAligned{
  @include flex-row;

  gap: 40px;
}

.displayMethod{
  @include flex-row;

  gap: 12px;
}

@media screen and (min-width: $XS-MOBILE-BREAKPOINT) {
  .actionBar {
    gap: 48px;
  }
}
