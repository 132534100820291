@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';
@import 'src/styles/constants';

.fieldSelectContainer {
  min-width: 230px;
  max-width: 100%;
}

.currentOption {
  @include flex-row;

  gap: 4px;
}

.currentOptionText {
  text-align: left;
  text-wrap: nowrap;

  @include text-overflow-ellipsis(1);
}

.fieldSelect {
  position: relative;
}

.disabled {
  & .fieldSelect {
    background-color: $grey_disabled-BG;
    color: $grey_caption-disabled-text;
    cursor: default;
  }

  & .chevron {
    background-color: $grey_disabled-BG;
    cursor: default;

    & path {
      stroke: $grey_caption-disabled-text;
    }
  }

  & .chevron:hover {
    background-color: $grey_disabled-BG;

    & path {
      stroke: $grey_caption-disabled-text;
    }
  }
}

.chevron {
  position: absolute;
  top: 6px;
  right: 4px;
  width: 32px;
  height: 32px;
  background-color: inherit;
  cursor: pointer;

  @include flex-row-center;

  &:hover {
    border-radius: 50%;
    background-color: $grey_main-BG-stroke;

    & path {
      stroke: $black_main-text;
    }
  }
}

.optionsContainer {
  @include flex-column;

  gap: 16px;
}

.options {
  @include flex-column;

  gap: 8px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .chevron {
    top: 3px;
  }
}
