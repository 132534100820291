@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/mixin.scss';

.content {
  @include flex-column;
  row-gap: 8px;

  .subtitle {
    @extend .label-20;
    color: $grey_caption-disabled-text;
  }
}

.features {
  .feature {
    height: 492px;
    @include flex-column;
    justify-content: space-between;

    .cardImg {
      margin-bottom: -20px;
      border-radius: 0 0 20px 20px;
      width: 100%;
    }
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .bottomRow {
    @include flex-row;
    column-gap: 24px;
  }

  .topRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 284px 284px; 
    gap: 24px;
    margin-bottom: 24px;

    div:nth-child(1) {
      grid-column: 1 /  2;
      grid-row: 1 /  3;


      .content {
        padding: 32px 32px 0px 32px;
      }
    }

    div:nth-child(2), div:nth-child(3) {
      grid-column: 2 / 3;
    }
  }

  .features {
    .feature {
      height: auto;
      padding: 0;
      overflow: hidden;
      @include flex-column;
      justify-content: space-between;

      .content {
        padding: 20px 24px 0px 24px;
        gap: 0;

        .subtitle {
          margin-bottom: 8px;
        }
      }

      .cardImg {
        margin: 0;
        width: 100%;
        object-position: bottom;
      }
    }
  }

  .list {
    .feature {
      .cardImg {
        margin-bottom: 0;
      }
    }
  }
}