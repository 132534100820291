@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$MODAL_ICON_HEIGHT: 50px;
$MODAL_TITLE_HEIGHT: 50px;
$MODAL_FOOTER_HEIGHT: 40px;
$MODAL_VERTICAL_PADDING: 140px;
$MODAL_VERTICAL_OUTER_MARGIN: 40px;
$MODAL_RESERVED_HEIGHT: $MODAL_ICON_HEIGHT + $MODAL_TITLE_HEIGHT + $MODAL_FOOTER_HEIGHT + $MODAL_VERTICAL_PADDING + $MODAL_VERTICAL_OUTER_MARGIN;

.container {
  @include flex-column;
  gap: 16px;
  height: 100%;
}

.headerBlock, .title {
  @include flex-column;
  gap: 8px;
}

.title {
  gap: 12px;
}

.headerWithClose {
  @include flex-row;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
}

.scrollBody {
  overflow-y: auto;
  @include flex-column;
  gap: 20px;
  flex-grow: 1;
}

.footer {
  padding-top: 8px;
  @include flex-column;
}

.topIcon {
  align-self: flex-start;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .container {
    max-width: 100%;
  }

  .scrollBody {
    height: auto;
    max-height: calc(100vh - $MODAL_RESERVED_HEIGHT);
  }
}
