@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.singleSocial {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
}

.google {
  background-color: #EA4335;
  
  &:hover {
    background-color: $red_error-500;
  }
}

.facebook {
  background-color: #1877F2;
  
  &:hover {
    background-color: $blue_main-500;
  }
}

.apple {
  background-color: $black_main-text;
  
  
  &:hover {
    background-color: $grey_secondary-text;
  }
}