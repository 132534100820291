@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.plansList {
  @include flex-column;
  row-gap: 20px;

  .learnMoreBtn {
    color: $blue_main-500;
    @include flex-row;
    justify-content: flex-end;
  }
}

.singlePlan {
  @include flex-column;
  row-gap: 20px;
}

.featuresList, .benefitsList {
  @include flex-column;
  row-gap: 20px;
}

.item {
  @include flex-row;
  align-items: flex-start;
  column-gap: 8px;
  color: $grey_secondary-text;

  @include svg-stroke-current-color;

  .iconGreen {
    color: $green_success-400;
  }

  .itemText {
    color: $grey_secondary-text;

    span {
      color: $grey_secondary-text;
    }
  }
}

.header {
  @include flex-row;
  justify-content: space-between;

  .price {
    color: $blue_main-500;
    padding: 8px 16px;
    border-radius: 99px;
    background: $blue_main-100;
  }  
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .plansList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    div:nth-child(1) {
      grid-column: 1 /  2;
    }

    div:nth-child(2) {
      grid-column: 2 / 4;
    }

    div:nth-child(3) {
      grid-column: 1 / 4;
    }

    .singlePlan {
      padding: 40px;
    }
  }

  .benefitsList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 20px;
  }

  .item {
    align-items: flex-start;
  }


  .plansList {
    .learnMoreBtn {
      flex-grow: 1;
      align-items: end;
    }
  }
}