@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';

.buttonRow {
  @include flex-column;
  gap: 12px;
}

.footerReset {
  p {
    align-self: center;
  }
  @include flex-column;
  gap: 16px;
  padding-top: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .buttonRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .footerReset {
    gap: 0;
    align-items: center;
  }
}