$DESKTOP-BREAKPOINT: 1280px;
$MOBILE-BREAKPOINT: 575px;
$XS-MOBILE-BREAKPOINT: 360px;

$BASE_HEIGHT: 100vh;
$BASE_PADDING: 16px;
$MOBILE_TOP_PADDING: 24px;
$MOBILE_BOTTOM_PADDING: 12px;

$FOOTER_HEIGHT: 58px;
$INPUT_HEIGHT: 40px;
$MOBILE_INPUT_HEIGHT: 44px;

$BASE_WIDTH: 1320px;
$BASE_WIDTH_STATIC: 994px;
$MOBILE_BASE_WIDTH: $MOBILE-BREAKPOINT + 2 * $BASE_PADDING;
$MOBILE_WIDTH: min($MOBILE_BASE_WIDTH, calc(100vw - 2 * $BASE_PADDING));
$MOBILE_STICKY_LEFT: max($BASE_PADDING, calc((100vw - $MOBILE_WIDTH) / 2));

$HEADER_PADDING: 8px;
$HEADER_LOGO_HEIGHT: 28px;
$HEADER_USER_AVATAR_SIZE: 24px;
$HEADER_HEIGHT: $HEADER_LOGO_HEIGHT + $HEADER_PADDING * 2;
$MOBILE_HEADER_HEIGHT: 60px;

$SEARCHBAR_V_PADDING: 28px;
$SEARCHBAR_HEIGHT: $INPUT_HEIGHT + $SEARCHBAR_V_PADDING * 2;

$DROPDOWN_OPTION_H_PADDING: 14px;
$DROPDOWN_OPTION_ICON_SIZE: 20px;

$MESSENGER_PADDING_TOP: 119px;
$MESSENGER_PADDING_BOTTOM: 40px;
$MESSENGER_MESSAGE_MAX_WIDTH: 388px;
$MESSENGER_MESSAGE_MAX_WIDTH_MOBILE: 236px;
$MESSENGER_OCCUPIED_SPACE: $HEADER_HEIGHT + $FOOTER_HEIGHT + $MESSENGER_PADDING_BOTTOM + $MESSENGER_PADDING_TOP;
$MESSENGER_MESSAGE_GROUP_PADDING: 12px;

$SMTH_WENT_WRONG_CONTENT_WIDTH: 448px;
$SMTH_WENT_WRONG_CONTENT_WIDTH_HORIZ: 376px;

$MESSENGER_WINDOW_HEIGHT: calc($BASE_HEIGHT - $MESSENGER_OCCUPIED_SPACE);

$REGULAR_BUTTON_HEIGHT: 40px;
$STICKY_BUTTON_MARGIN_BOTTOM: 12px;
$STICKY_BUTTON_POSITION_BOTTOM: $MOBILE_HEADER_HEIGHT + $STICKY_BUTTON_MARGIN_BOTTOM;
$STICKY_BUTTON_SPACE: $STICKY_BUTTON_MARGIN_BOTTOM + $REGULAR_BUTTON_HEIGHT + $MOBILE_BOTTOM_PADDING;

$OFFER-PAGE-SLIDER-HEIGHT: 238px;
$OFFER-PAGE-SLIDER-HEIGHT-DESKTOP: 472px;
$OFFER-PAGE-THUMBNAILS-SLIDER-HEIGHT: 64px;

$OFFER-PAGE-FULLSCREEN_SLIDER_TOP_PADDING: 115px;
$OFFER-PAGE-FULLSCREEN_SLIDER_TOP_PADDING_MOBILE: 56px;
$OFFER-PAGE-FULLSCREEN_SLIDER_BOTTOM_PADDING: 105px;
$OFFER-PAGE-FULLSCREEN_SLIDER_BOTTOM_PADDING_MOBILE: 68px;
$OFFER-PAGE-FULLSCREEN_SLIDER_CAROUSEL_BUTTON_SPACE: 124px;
$OFFER-PAGE-THUMBNAILS-SLIDE-WIDTH: 106px;

$VERTICAL_SHOWCASE_TOP_PADDING: 32px;

$Z-IND-MODAL-CONTENT: 101;
$Z-IND-MODAL-OVERLAY: 100;
$Z-IND-TOAST-OVERLAY: 10;
$Z-IND-DROPDOWN: 3;
$Z-IND-STICKY: 2;
$Z-IND-ONTOP: 1;
$Z-IND-NEUTRAL: 0;
$Z-IND-BACKGROUND: -1;