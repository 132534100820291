@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.sortBtnWrapper {
  @include flex-row;
  justify-content: end;
}

.searchCard {
  cursor: pointer;
  @include flex-row;
  gap: 4px;
  padding: 12px 16px;
  border-radius: 12px;
  justify-content: space-between;

  background-color: $white_BG;

  &:hover .searchTitle{
    color: $purple_hover;
  }
}

.searchDisplayBlock {
  @include flex-column;
  gap: 8px;
  align-items: flex-start;
}

.savedSearchList {
  @include flex-column;
  gap: 8px;
}

.searchTitle {
  font-size: 18px;
  font-weight: 600;
  @include text-overflow-ellipsis(1);
}

.searchParams {
  font-size: 14px;
  @include text-overflow-ellipsis(2);
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .searchCard {
    gap: 40px;
  }

  .savedSearchList {
    gap: 12px;
  }

  .searchParams {
    font-size: 16px;
    text-overflow: unset;
    -webkit-line-clamp: unset;
    overflow: visible;
  }
}
