@import 'src/styles/mixin';
@import 'src/styles/typography';

.container {
  @include flex-column;

  gap: 24px;
}

.main {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.list {
  gap: 12px;
  grid-column: span 8;

  @include flex-column;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .search {
    width: 50%;
    padding-bottom: 0;
  }
}