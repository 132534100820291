@import '../../styles/mixin.scss';
@import '../../styles/color.scss';

.main {
  @include flex-row;
  column-gap: 8px;
  align-items: start;

  &.large {
    .switch{
      width: 48px;
      min-width: 48px;
      height: 28px;
    }

    .toggle {
      border-radius: 16px;

    &:before {
      height: 22px;
      width: 22px;
      left: 2px;
      bottom: 3px;
      }
    }

    .switchInput:checked + .toggle:before {
      transform: translateX(22px);
    }
  }
}

.disabled {  
  pointer-events: none;

  .switchInput:checked + .toggle {
      background-color: $grey_main-BG-stroke;
    }

  .toggle:before {
    background-color: $grey_disabled-BG;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.10)
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  min-width: 36px;
  height: 20px;
}

.switchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.label {
  color: $black_main-text !important;
}

.supportiveText {
  color: $grey_caption-disabled-text;
}

.textBlock {
  @include flex-column;
  gap: 4px;
}

.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  background-color: $grey_focused-stroke-scroll-bar;
  @include transition(all, 0.4s);

  &:hover {
    background-color: $grey_caption-disabled-text;
  }

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: 2px;
    bottom: 2px;
    background-color: $white_BG;
    @include transition(all, 0.4s);
  }
}

.switchInput:checked + .toggle {
  background-color: $blue_main;
}

.switchInput:focus + .toggle {
  box-shadow: 0 0 1px $blue_main-500;
}

.switchInput:checked + .toggle:hover {
  background-color: $purple_hover;
}

.switchInput:checked + .toggle:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
