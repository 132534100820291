@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.offersContainer {
  margin-top: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .offersContainer {
    margin-top: 16px;
  }
}
