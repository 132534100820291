@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.verifyPhoneContainer {
 max-width: 448px;
 height: 100%;
}

.verifyPhoneForm {
  @include flex-column;

  height: 100%;
  gap: 24px;
}

.mainText {
  text-align: center;
}

.noWrap {
  white-space: nowrap;
}

.buttonRow {
  display: grid;
  gap: 12px;
  grid-template-rows: 1fr 1fr;
}

.verifyButton {
  width: 100%;
  margin-top: 24px;
}

.enterCodeFooter {
  @include flex-column;

  flex-direction: column-reverse;
  gap: 16px;
}

.footerResend {
  @include flex-column;

  margin-top: 12px;
  gap: 12px;
}

.oneLine {
  @include flex-row-center;

  gap: 8px;
}

.counterDown {
  color: $grey_caption-disabled-text;
  text-align: center;
}

.checkIcon {
  color: $green_success-400;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .buttonRow {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .enterCodeFooter {
    flex-direction: column;
  }
}