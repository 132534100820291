@import 'src/styles/constants.scss';

.main {
  margin-top: 24px;
}

.showMoreBtn {
  margin-top: 12px;
  width: fit-content;
}

.emptyState {
  margin-top: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    display: grid;
    margin-top: 60px;
    grid-template-columns: 7fr 3fr;
  }
}
