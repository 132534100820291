@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/typography.scss';

.main {
  @include flex-column;
  row-gap: 20px;
  margin: 60px 0 28px;

  .subheading {
    color: $grey_secondary-text;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    margin: 128px 0 44px;

    .subheading {
      @include desktopBodySize28;
    }
  }
}
