@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';

.main {
  padding: 0px;
}

.groupTitle {
  padding: 8px 20px 0px 20px;
}

.heading {
  @include flex-row;
  column-gap: 12px;
}

.list,
.item {
  background-color: $white_BG;
}

.list {
  @include flex-column;

  gap: 4px;
  padding: 12px 0;
  border-radius: 12px;
}

.item {
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 4px 8px;
  border-radius: 6px;
  color: $grey_secondary-text;
  @extend .label-16;
  @include flex-row;
  gap: 4px;
  text-align: left;

  &:hover {
    color: $purple_hover;

    & .icon {
      svg {
        path {
          stroke: $purple_hover;
        }
      }
    }
  }

  &.active {
    background-color: $blue_main-100;
    color: $black_main-text;

    & .icon {
      svg {
        path {
          stroke: $black_main-text;
        }
      }
    }
  }
}

.leftContent {
  @include flex-row-center;
  gap: 4px;
}

.large {
  @extend .bodySizeM;
  padding: 8px 20px;
  width: 100%;
  gap: 4px;
  justify-content: space-between;

  .leftContent {
    gap: 12px;
  }
}

.iconRotate{
  transform: rotate(90deg);
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .title {
    margin: 0 20px 16px 20px;
    color: $grey_secondary-text;
  }
}