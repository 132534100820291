@import './mixin.scss';
@import './color.scss';
@import './typography.scss';
@import './constants.scss';

.Toastify {
  &__toast-container {
    width: 100%;
    @include flex-column-center;
    max-width: $MOBILE_BASE_WIDTH;
  }

  &__toast {
    width: calc(100% - 2 * $BASE_PADDING);
    margin-bottom: 1rem; // values from toast library
    padding: 8px; // values from toast library
    border-radius: var(--toastify-toast-bd-radius); // values from toast library
    min-height: initial;
    padding: 4px 8px 4px 16px;
    color: $white_BG;
    box-shadow: 0px -1px 4px 0px rgba($shadow-color-2, 0.12), 0px 4px 4px 0px rgba($shadow-color-1, 0.12);

    .Toastify__toast-body,
    button {
      @include svg-stroke-current-color;
    }

    &--default:hover {
      background-color: $grey_focused-stroke-scroll-bar;
    }

    &--error,
    &--success {
      .Toastify__toast-body,
      button {
        @include svg-stroke-current-color;

        color: $white_BG;
      }
    }

    &--error {
      background-color: $red_error-500;
    }

    &--success {
      background-color: $black_main-text;
    }
  }

  &__toast-icon,
  &__toast-body {
    margin-inline-end: 8px;
  }

  &__toast-body {
    @include bodySizeS;

    height: 32px;
    padding: 0;
  }

  button {
    @include flex-row-center;

    width: 24px;
    height: 24px;
    padding: 0;
    margin: auto 0;
    border-radius: 50%;

    &:hover {
      color: $black_main-text;
      background-color: $transparent-hovers-light-on-dark;
    }
  }

  &__progress-bar--info, &__progress-bar--success {
    background-color: $grey_caption-disabled-text;
  }
  &__toast--success, &__toast--info {
    background-color: $black_main-text;
  }
  &__toast--error {
    background-color: $red_error-500;
  }
  &__progress-bar--error {
    background-color: $red_error-300;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .Toastify__toast-container {
    width: initial;
  }

  .Toastify__toast {
    width: initial;
  }
}