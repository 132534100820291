.main {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  padding: 16px 16px 24px 16px;
  border-radius: 16px;

  .title,
  .content {
    grid-column: 1 / span 10;
  }
}
