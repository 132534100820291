@import '../../../styles/color';
@import '../../../styles/mixin';
@import '../../../styles/typography.scss';

.searchIcon { 
  position: absolute;
  top: 12px;
  left: 12px;
}

.searchContainer {
  position: relative;
  width: 100%;

  .textInput {
    padding-left: 40px;
  }
}

.closeBtn {
  position: absolute;
  border: none;
  right: 8px;
  background-color: transparent;
  height: 100%;
}

.wrapper {
  width: 100%;
  position: relative;
}

.hasRightIcon {
  padding-right: 44px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .searchIcon {
    top: 8px;
  }
}