@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

.customerActionsContainer {
  @include flex-column;
  gap: 28px;
}

.suggestPrice{
  display: flex;
  gap: 4px;
}

.priceAndFeatures, .featureList{
  @include flex-column;
  gap: 16px;
}

.featureMainInfo{
  display: flex;
  gap: 8px;
}

.featureHint{
  color: $grey_caption-disabled-text;
}

.postActionButtons, .writeMsgButton {
  @include flex-column;
  gap: 12px;
}

.inactivePostActionButton {
  @include flex-column;
  margin-top: 28px;
}

.writeMsgButton{
  flex-direction: column-reverse; // mobile
}

.answerTime{
  color: $grey_caption-disabled-text;
  text-align: center;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .writeMsgButton{
    flex-direction: column;
    gap: 8px;
  }

  .answerTime{
    text-align: start;
  }
}

