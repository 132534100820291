@import 'src/styles/color';
@import 'src/styles/mixin';

.main {
  color: $black_main-text;

  &.color {
    color: $blue_main-500;

    &.visited {
      color: $purple_visited;
    }

    &.disabled {
      color: $grey_caption-disabled-text;
    }
  }

  &,
  &.color,
  &.color.visited {
    .disable {
      color: $grey_caption-disabled-text;
    }
  }
}

.allLines {
  @include text-overflow-ellipsis(0);
}

.oneLine {
  display: inline;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.twoLines {
  @include text-overflow-ellipsis(2);
}
