@import 'src/styles/mixin';
@import 'src/styles/constants';

$FULLSCREEN_SIDE_PADDING_MOBILE: 16px;

.main {
  width: 100%;
  margin: 0 auto 20px;
}

.blur {
  position: absolute;
  z-index: $Z-IND-BACKGROUND;
  top: 0;
  width: 100%;
  aspect-ratio: 3/2;
  background-repeat: no-repeat;
  background-size: 200%;
  filter: blur(16px);
  opacity: 0.4;
}

.image {
  align-self: flex-start;
  cursor: pointer;
}

// for case when there is no swiper - only one photo
.single {
  @include flex-row-center;

  position: relative;
  z-index: $Z-IND-NEUTRAL;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/2;

  & img {
    width: 100%;
    height: 100%;
    min-height: $OFFER-PAGE-SLIDER-HEIGHT;
    max-height: $OFFER-PAGE-SLIDER-HEIGHT-DESKTOP;
    object-position: center;
  }
}

.counterOverlay {
  position: absolute;
  z-index: $Z-IND-ONTOP;
  bottom: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.counter {
  border-radius: 6px;
  background-color: $white_BG_80;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    margin: 20px auto 52px;
  }

  .image, .blur {
    height: 100%;
    max-height: 100%;
  }

  .single {
    height: $OFFER-PAGE-SLIDER-HEIGHT-DESKTOP;
    border-radius: 12px;
  }
}
