@import './color';
@import './mixin';
@import './reset';
@import './toast';
@import './common';
@import './static';
@import './slider';
@import './constants';
@import './typography';
@import './responsive';
@import './aboutPageSwiper';

body {
  margin: 0;
  font-family: $font-family-inter,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  outline-color: $blue_main-400;
  box-sizing: border-box;
}

body {
  display: flex;
  margin: 0;
  min-height: 100vh;
  min-height: 100dvh;
  background-color: $grey_disabled-BG;
  color: $black_main-text;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  margin-bottom: env(safe-area-inset-bottom); /* Fallback for older devices */
  margin-bottom: constant(safe-area-inset-bottom); /* For newer devices with safe areas */
}

body[data-theme='light'] {
  --colors-bg: #FAFAFA; // $grey_disabled-BG
  --colors-text: #292929; // $black_main-text
}

body[data-theme='dark'] {
  --colors-bg: #292929; // $black_main-text
  --colors-text: #FFFFFF; // $white_BG
}

small {
  font-size: 11px;
  line-height: 16px;
}

a {
  text-decoration: none;
  color: $grey_secondary-text;
  outline: none;
}

strong {
  font-weight: bold;
}

img {
  vertical-align: top;
}

button {
  cursor: pointer;
}

button,
input {
  border-radius: 3px;
}

form {
  width: 100%;

  fieldset {
    width: 100%;
  }
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.tooltip {
  @include box-shadow($shadow-color-1);

  z-index: $Z-IND-MODAL-CONTENT;
  border-radius: 8px;
  max-width: 320px;

  & h5 {
    @extend .label-16;

    margin-bottom: 8px;
  }

  & p {
    @extend .caption-12;

    margin-bottom: 4px;
  }
}

#light-tooltip {
  background: $white_BG;

  & h5 {
    color: $black_main-text;
  }

  & p {
    color: $grey_secondary-text;
  }
}

#dark-tooltip {
  background: $black_main-text;

  & h5 {
    color: $white_BG;
  }

  & p {
    color: $white_BG;
  }
}

.modal-open {
  overflow: hidden;
}

.gm-style iframe + div {
  border: none!important;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .tooltip {
    z-index: $Z-IND-DROPDOWN;
  }
}
