@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';

.filterMain {
  padding: 16px 16px 0 16px;
}

.filterContainer {
  position: relative;
}

.fieldInput {
  @include bodySizeM;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid $grey_main-BG-stroke;;
  color: $black_main-text;
  resize: none;

  &:focus, &:active, &:focus-visible {
    outline: 1px solid $grey_focused-stroke-scroll-bar;
  }
}

.filtersDropdown{
  top: 0px;
}

.textInput {
  padding-left: 40px;
  padding-right: 40px;
}

.searchIcon {
  position: absolute;
  top: 10px;
  left: 18px;
}

.filtersIcon {
  position: absolute;
  bottom: 5px;
  right: 8px;
}

.input {
  button {
    right: 44px;
    bottom: 5px;
    height: auto;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .filterContainer {
    padding-left: 12px;
    padding-right: 12px;
  }

  .filterMain {
    padding-right: 0;
    padding-left: 0;
  }
}




