@import '../../../../styles/color.scss';
@import '../../../../styles/mixin.scss';

@import '../../profileLayout.module.scss';

.main{
  @include flex-column;
  padding-top: 24px;
  gap: 16px;

  h1 {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main{
    padding-top: $margin-bottom-profile-title-section + $h1-title-height;
  }
}
