@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.badgesContainer {
  @include flex-row;

  flex-wrap: wrap;
  margin-left: 20px;
  gap: 12px;

  .settingsBadge {
    background-color: $grey_disabled-BG;

    &:hover {
      background-color: $blue_main-100;
    }
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .badgesContainer {
    margin-left: 28px;
  }
}