@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

$CATEGORY_CARD_GAP: 8px;
$CATEGORY_CARD_HEIGHT: 88px;
$CATEGORY_CARD_WIDTH: 112px;
$CATEGORY_CARD_WIDTH_LARGE: 154px;

.categoryCardsContainer {
  @include flex-column;
  @include hide-scrollbar;

  gap: $CATEGORY_CARD_GAP;
  width: calc(min(100vw, $MOBILE_BASE_WIDTH - $BASE_PADDING) - $BASE_PADDING);
  flex-wrap: nowrap;
  overflow: auto;
}

.line {
  @include flex-row;

  gap: $CATEGORY_CARD_GAP;
}

.card {
  @include flex-row;

  width: $CATEGORY_CARD_WIDTH;
  min-width: $CATEGORY_CARD_WIDTH;
  height: $CATEGORY_CARD_HEIGHT;
  align-items: flex-start;
  padding: 12px;
  background-color: $grey_focused-stroke-scroll-bar;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  border-radius: 8px;

  &.large {
    width: $CATEGORY_CARD_WIDTH_LARGE;
    min-width: $CATEGORY_CARD_WIDTH_LARGE;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .categoryCardsContainer {
    width: 100%;
    overflow: auto;
  }

  .card {
    margin-right: 0;
  }
}
