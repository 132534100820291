@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.main {
  display: inline-flex;
  gap: 4px;
  padding: 4px 8px 4px 12px;
  border-radius: 20px;
  @include flex-center;
  cursor: pointer;

  button {
    border-radius: 50%;
    @include transition(all, 0.1s, ease-in-out);
  }
}

.nonBreakableText {
  white-space: nowrap;
}

.dark {
  background-color: $black_main-text;
  color: $white_BG !important;

  svg path {
    stroke: $white_BG;
  }

  &:hover {
    background-color: $grey_secondary-text;

    button {
      background-color: $white_BG;
    }
  }
}

.light {
  background-color: $grey_main-BG-stroke;
  color: $black_main-text;

  & .chipRight {
    color: $black_main-text;
  }

  &:hover {
    button {
      background-color: $black_main-text-20;
    }
  }
}

.chipLeft{
  color: $grey_secondary-text;
}
