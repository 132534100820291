@import '../../../styles/color';
@import '../../../styles/mixin';
@import '../../../styles/typography.scss';

.categoryFormBlock {
  @include flex-column;
  gap: 20px;
}


@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .categoryFormBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
