@import '../../../styles/color';
@import '../../../styles/mixin';

.main {
  @include flex-row-center;

  justify-content: left;
  border: none;
  border-radius: 6px;
  background-color: $white_BG;
  text-wrap: nowrap;

  &:hover {
    background-color: $blue_main-100;
    color: $black_main-text;
  }

  &:disabled {
    background-color: $grey_main-BG-stroke;
    cursor: default;
  }
}

.left {
  margin-right: 12px;
  margin-left: 8px;
}

.large {
  width: 100%;
  height: 36px;
}

.small {
  width: fit-content;
  padding: 4px 8px;
  border-radius: 999px;
  background-color: $blue_main-100;
  color: $black_main-text;
}
