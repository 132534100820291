@import '../../styles/color';
@import '../../styles/mixin';
@import '../../styles/typography';

.btnWrapper {
  @include flex-row;
  @include svg-stroke-current-color;
  @extend .bodySizeM;

  align-items: center;
  border-radius: 8px;
  column-gap: 8px;

  &.small {
    @extend .supportive-14;

    padding: 5px 12px;
    border-radius: 4px;
  }

  &.medium {
    padding: 12px 20px;
  }

  &.large {
    padding: 16px 20px;
    border-radius: 10px;
  }

  &:disabled {
    cursor: default;
  }
}

.main {
  @include flex-center;

  width: 100%;
  border: none;
  background-color: $blue_main-500;
  color: $white_BG;

  &:hover {
    background-color: $purple_hover;
  }

  &:disabled {
    background-color: $grey_caption-disabled-text;
  }
}

.icon {
  width: 20px;
  height: 20px;
  margin: 0 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.5;
  }
}

.transparentNoBorderLight {
  &.small, &.medium, &.large {
    padding: 0;
  }

  border: none;
  background-color: transparent;
  color: $blue-main;

  @include flex-row-center;
  @extend .label-14;

  &:hover {
    color: $purple_hover;
  }

  &:disabled {
    color: $grey_caption-disabled-text;
  }
}

.transparentNoBorder {
  @include flex-row;
  @include label;

  &.small, &.medium, &.large {
    padding: 0;
  }

  border: none;
  background-color: transparent;

  @extend .label-14;

  &:hover {
    color: $purple_hover;
  }

  &:disabled {
    color: $grey_caption-disabled-text;
  }

  &.large {
    @extend .label-18;
  }
}

.transparentWithBorder{
  @include flex-row-center;

  border: 2px solid $grey_secondary-text;
  background-color: transparent;
  color: $black_main-text;

  &.large {
    padding: 16px;
  }

  &:hover {
    border: 2px solid $purple_hover;
    color: $purple_hover;
  }

  &:disabled {
    background-color: $grey_caption-disabled-text;
  }
}

.blackNoBorder{
  @include flex-row-center;

  border: none;
  background-color: $black_main-text;
  color: $white_BG;

  &:hover{
    background-color: $grey_secondary-text;
  }

  &:disabled {
    background-color: $grey_focused-stroke-scroll-bar;
  }
}

.moderation {
  @include flex-row-center;

  border: none;
  background-color: $red-error-500;
  color: $white_BG;

  &:hover{
    background-color: $red-error-700;
  }

  &:disabled {
    background-color: $grey_caption-disabled-text;
    cursor: default;
  }
}

@media (min-width: $DESKTOP-BREAKPOINT) {
  .btnWrapper {
    width: initial;
  }

  .main, .blackNoBorder, .moderation, .transparentWithBorder {
    &.medium {
      padding: 8px 20px;
    }
  }
}
