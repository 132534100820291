@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants';

.checkPoints {
  @include flex-column;
  gap: 8px;
}

.singleCheckPoint {
  @include flex-row;
  gap: 8px;
  align-items: center;
}

.text {
  color: $grey_caption-disabled-text;
}

.fail {
  color: $red_error-500;
}

.pass {
  color: $green_success-500;
}