@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.list {
  @include flex-column;
  row-gap: 16px;
  margin: 24px 0;
}

.main {
  & .subtitle {
    margin-top: 20px;
    color: $grey_secondary-text;
  }
}

.listItem {
  @include flex-column;
  row-gap: 8px;
  & div {
      margin-bottom: 8px;
      justify-content: flex-start;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .list {
    margin-top: 44px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto auto;
    grid-gap: 24px;

    & .listItem {
      padding: 24px;
      border-radius: 20px;

      &:nth-child(5) {
        grid-column: 1 / 3;
      }

      &:nth-child(6) {
        grid-column: 3 / 5;
      }
    }
  }
}
