@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.label {
  width: 100%;
  @include flex-row;
  align-items: center;
  padding: 8px 0;
}

.checkbox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:not(:checked):hover ~ .visibleInput::before {
    border-color: $purple_hover;
  }

  &:checked {
    & ~ .visibleInput::before {
      background-color: $blue_main-500;
      background-image: url('../../../../public/icons/icons-16/tick.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover ~ .visibleInput::before {
      background-color: $purple_hover;
    }
  }
}

.visibleInput {
  @include flex-row;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  user-select: none;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid $grey_main-BG-stroke;
    background: $white_BG;
    @include transition(all, 0.2s, ease-in-out);
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .label {
    padding: 0;
  }
}
