@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants';

.main {
  @include flex-column-center;

  width: 100%;
  padding-top: 28px;
  padding-bottom: 28px;
  border-top: 1px solid $grey_main-BG-stroke;
  margin-top: auto;
  background-color: $white_BG;

  .layout {
    margin-bottom: 48px;
  }
}

li {
  list-style: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 6fr 4fr 6fr;
}

.wrapperSmall {
  @include flex-row;

  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  color: $grey_secondary-text;

  a {
    color: $grey_secondary-text;
  }
}

.linksRow {
  @include flex-row-center;

  flex-wrap: wrap;
  gap: 20px 24px;
}

.socialMediaRow {
  @include flex-row;

  margin-top: auto;
  column-gap: 12px;

  svg {
    path {
      fill: $grey_secondary-text;
    }
  }
}

.logo {
  svg {
    height: $HEADER_LOGO_HEIGHT;
  }
}

.sectionTitle {
  margin-bottom: 20px;
  color: $grey_secondary-text;
}

.section, .sectionList {
  @include flex-column;
}

.copyright {
  margin-top: 16px;
  color: $grey_secondary-text;
}

.sectionList {
  row-gap: 16px;
}

.sectionListItem a {
  color: $grey_secondary-text;
}

.linkWrapper {
  padding-left: 8px;

  a {
    text-decoration: underline;
  }
}

.wrapperNarrow {
  flex-direction: column;
  margin-left: 0;
  row-gap: 20px;
}

.smallLegalLinks {
  @include flex-row-center;

  flex-wrap: wrap;
  gap: 12px;
  text-align: center;
}

.mobileFooterContainer {
  @include flex-column-center;

  padding: 32px 0;
  gap: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    .layout {
      margin-bottom: 0;
    }
  }

  .linksRow {
    @include flex-row;

    column-gap: 48px;
  }
}