@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';
@import 'src/styles/typography';

@mixin message-main {
  @include flex-row;

  margin-top: 8px;
  gap: 8px;
}

.messageWithDate {
  @include caption;

  display: flex;

  // padding for first message
  padding-top: 16px;
  font-size: $font-size-12;
  gap: 8px;
}

.messageUserMain {
  @include message-main;

  justify-content: flex-end;

  & + .messageUserMain {
    padding-top: 8px;
    padding-right: 40px;

    .avatarWrapper {
      display: none;
    }

    .messageWithDate{
      padding-top: 0;
    }
  }
}

.messageStrangerMain {
  @include message-main;

  justify-content: flex-start;

  & + .messageStrangerMain {
    padding-top: 8px;
    padding-left: 40px;

    .avatarWrapper {
      display: none;
    }

    .messageWithDate{
      padding-top: 0;
    }
  }
}

.messageStrangerMain + .warningMain {
  padding-left: 0;
}

.warningMessagesContainer {
  @include flex-column;

  gap: 4px;
}

.yomeAvatar {
  @include flex-center;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $blue_main-500;
  color: $white_BG;
}

.userMessageText,
.strangerMessageText {
  @extend .supportive-14;

  max-width: $MESSENGER_MESSAGE_MAX_WIDTH_MOBILE;
  padding: 12px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.userMessageText {
  border-radius: 12px 2px 12px 12px;
  margin-left: auto;
  background-color: $blue_main-100;
}

.strangerMessageText {
  border-radius: 2px 12px 12px;
  margin-right: auto;
  background-color: $gray-dark-light;
}

.warningText {
  background-color: $green_success-100;

  @include flex-column;

  gap: 8px;
}

.warningButtonsContainer {
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  gap: 8px;

  @include flex-column;
}

.helpfulBlockContainer {
  align-items: center;
  margin-top: 16px;
  gap: 8px;

  @include flex-column;
}

.contactSupportButton {
  width: 100%;
}

.helpfulButtonsContainer {
  @include flex-row;

  align-items: center;
  gap: 8px;
}

.warningButtonsContainer .helpfulButton {
  padding: 8px 16px;
  background-color: $white_BG;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .userMessageText,
  .strangerMessageText {
    max-width: $MESSENGER_MESSAGE_MAX_WIDTH;

    @include bodySizeS;
  }

  .warningText {
    min-width: $MESSENGER_MESSAGE_MAX_WIDTH;
  }

  .messageWithDate {
    gap: 16px;
  }

  .warningButtonsContainer {
    @include flex-row;
  }

  .helpfulBlockContainer {
    @include flex-row;

    margin-top: 0;
  }

  .warningButtonsContainer .helpfulButton {
    padding: 8px;
  }

  .contactSupportButton {
    width: unset;
  }
}
