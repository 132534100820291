@import '../../styles/color';
@import '../../styles/mixin';
@import '../../styles/typography';

.main {
  @include flex-row;

  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: $white_BG;
  color: $black_main-text;
  cursor: pointer;
  gap: 12px;

  .label {
    color: $black_main-text;
    text-align: left;
  }

  .chevron {
    @include flex-row-center;

    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  &:hover {
    background-color: $grey_main-BG-stroke;

    .label {
      color: $purple_hover;
    }

    .chevron {
      border-radius: 50%;
      background-color: $black_main-text-08;
    }
  }

  &.active {
    background-color: $grey_main-BG-stroke;
    color: $black_main-text;
  }

// TODO: get rid of it
  &.medium {    
    @include bodySizeM;
  }
}

.primary {
  @include flex-row;

  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.container {
  @include flex-column;

  align-items: flex-start;
  gap: 4px;
}

.secondary {
  color: $grey_secondary-text;
}
