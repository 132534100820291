@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/responsive.scss';

$CITY_MODAL_WIDTH: 984px;

.cityModal {
  @include mobile-paddings;

  width: 100%;
  height: 100%;
}

.controls {
  @include flex-row;
}

.resetButton {
  margin-right: 12px;
}

.buttonGroup {
  @include flex-row;

  margin-top: 50px;
  margin-left: auto;
}

.radiusTitle {
  margin: 20px 0 14px;
}

.radiusButtons {
  @include flex-row-center;
}

.radiusButton {
  margin-right: 8px;
  cursor: pointer;

  span {
    @include flex-row-center;

    width: 40px;
    height: 36px;
    padding: 8px 6px 10px 6px;
    border-radius: 8px;
    color: $black_main-text;
    background-color: $grey_main-BG-stroke;
  }

  input {
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;

    &:checked + span {
      color: $white_BG;
      background-color: $black_main-text;
    }
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .cityModal {
    width: $CITY_MODAL_WIDTH;
    border-radius: 10px;
    padding: 0;
  }

  .controls {
    align-items: flex-end;
  }

  .map {
    min-height: 400px;
  }
}
