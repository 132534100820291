@import 'src/styles/constants';
@import 'src/styles/mixin';
@import 'src/styles/typography';

.main {
  width: 100%; 

  @include flex-column-center;

  height: fit-content;
  row-gap: 8px;
}

.content {
  @include flex-column-center;

  max-width: $SMTH_WENT_WRONG_CONTENT_WIDTH;
  row-gap: 12px;
  text-align: center;
}

.button {
  margin-top: 8px;
}

.text {
  @extend .bodySizeL;
}

.mainHorizontal {
  height: 100%;
  margin-top: 20px;

  @include flex-column-center;

  column-gap: 8px;

  .content {
    @include flex-column;

    width: 100%;
  }
}

.comingSoon {
  width: 100%;
  margin-top: 36px;
}

.title{
  @include flex-row;

  align-items: center;
  gap: 6px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .mainHorizontal {
    height: auto;

    @include flex-row-center;

    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: 40px;

    .content {
      width: $SMTH_WENT_WRONG_CONTENT_WIDTH_HORIZ;
      align-items: baseline;
      row-gap: 16px;
      text-align: start;
    }
  }
}
