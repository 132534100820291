@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';

$CHAT_HEADER_TITLE_WIDTH_MOBILE: 112px;
$CHAT_DROPDOWN_WIDTH: 306px;
$CHAT_DROPDOWN_WIDTH_MOBILE: 258px;

.header {
  padding: 10px 16px 8px 12px;
  background-color: $white_BG;
  box-shadow: 0 2px 16px 0 rgba($shadow-color-1, 0.08);
}

.navigation {
  @include flex-row;
  @include flex-center;

  column-gap: 16px;
}

.content {
  @include flex-row;

  width: 100%;
}

.offerTitle {
  @include text-overflow-ellipsis(1);

  color: $black_main-text;
  text-wrap: wrap;
}

.tooltip {
  position: relative;
}

.itemInfo {
  @include flex-column;

  overflow: hidden;
  flex-grow: 1;
  cursor: default;
  font-weight: bold;
  white-space: nowrap;
}

.userInfo {
  @include flex-row;

  // TODO: get rid of this
  @extend .bodySizeL;

  position: relative;
  align-items: baseline;
  margin-bottom: 1px;
  gap: 4px;

  &:hover, a:hover {
    color: $purple_hover;
  }
}

.userInfoWrapper {
  @include flex-row;

  align-items: center;
  gap : 4px;
}

.postInfo {
  // TODO: get rid of this
  @extend .label-16;

  display: flex;
  color: $black_main-text;
  gap: 10px;

  &:hover {
    color: $purple_hover;

    a {
      color: $purple_hover;
    }
  }
}

.postImage {
  margin-top: 5px;
  margin-right: 12px;
}

.dropdown {
  right: 0;
  max-width: $CHAT_DROPDOWN_WIDTH_MOBILE;
  max-height: max-content;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .dropdown {
    max-width: $CHAT_DROPDOWN_WIDTH;
  }

  .offerTitle {
    max-width: 100%;
  }
}

