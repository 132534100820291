@import 'src/styles/constants.scss';

.main{
  display: flex;
  justify-content: left;
}

.chevron img{
  transform: rotate(90deg);
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main{
    justify-content: right;
  }
}