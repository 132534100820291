@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.list {
  @include flex-column;
  row-gap: 20px;
}

.card {
  @include flex-column;
  row-gap: 12px;
  padding: 20px 24px 36px 24px;
  border-radius: 20px;
}

.card-create {
  background-color: $Decorative-Lavender;
}

.card-choose {
  background-color: $Decorative-Mint;
}

.card-list {
  background-color: $Decorative-Banana;
}

.card-start {
  background-color: $Decorative-Kiwi;
  background-image: url('../../../../../public/images/for-business/darts.png');
  background-repeat: no-repeat;
  background-size: 158px;
  background-position: top right;
}

.btn {
  margin-top: 24px;
}

.title {
  margin: 16px 0 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .list {
    margin-bottom: 60px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    .card-start {
      grid-column: 1 / 4;
      background-size: 30%;
      background-color: $Decorative-Kiwi;
      background-image: url('../../../../../public/images/for-business/dartsDesktop.png');
      background-size: contain;
      background-position: bottom right;

      .content {
        max-width: 50%;
        @include flex-column;
        row-gap: 12px;

        a {
          max-width: 272px;
          margin-top: 12px;
        }
      }
    }
  }
}

