@import '../../styles/mixin.scss';
@import '../../styles/color.scss';

.main {
  @include flex-center;

  border: none;
  background-color: transparent;
  color: inherit;
  padding: 4px;

  &:hover {
    background-color: $black_main-text-08;
    border-radius: 50%;
  }
}

.disabled {
  @include svg-stroke-current-color;

  color: $grey_caption-disabled-text;
  cursor: default;
}