@import './mixin';
@import './color';
@import './constants';
@import './typography';

$PADDING: 20px;
$TABLE_WIDTH: 684px;

.static-content {
  .table {
    width: fit-content;
    min-width: $TABLE_WIDTH;
    border: 1px solid $grey_main-BG-stroke;
  }

  .table-head {
    font-weight: bold;
    background-color: $grey_disabled-BG;

    h4 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .table-row {
    display: grid;
    grid-template-columns: 4fr 4fr 5fr 3fr;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $grey_main-BG-stroke;
    }
  }

  .table-cell {
    padding: 10px 12px;

    &:not(:last-child) {
      border-right: 1px solid $grey_main-BG-stroke;
    }
  }

  p.table-cell {
    margin-bottom: 0;
  }
}

.static-page-wrapper {
  @include inner-static;
  width: 100%;
}

.static-h4 {
  border-radius: 99px;
  border: 1px solid $grey_caption-disabled-text;
  @extend .bodySizeL;
  color: $grey_caption-disabled-text;
  width: fit-content;
  padding: 7px 12px;
}

.static-block {
  background-color: $white_BG;
  border-radius: 20px;
  padding: 20px;
  @include box-shadow($shadow-color-3);

  .static-block-img {
    width: calc(100% + $PADDING * 2);
    margin-left: -20px;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
    margin-top: -20px;
  }

  .static-block-subheading {
    @extend .static-h4;
  }

  .static-block-heading {
    @include decorative-regular-sizeM();
    color: $blue_main-500;
    margin: 24px 0 20px;
  }

  .static-block-text {
    @include bodySizeL;
    color: $grey_secondary-text;
  }

  .static-block-button {
    margin-top: 20px;
  }
}

.static-stat-line {
  padding: 0 56px;
  margin: 60px 0;
  @include flex-column-center;
  row-gap: 16px;
}

.static-stat-line-item {
  @include flex-column-center;

  row-gap: 12px;
  text-align: center;

  h2 {
    color: $blue_main-500;
  }
}

.static-heading {
  margin: 24px 0 16px;

  span {
    color: $blue_main-500;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .static-block {
    padding: 60px;
    border-radius: 36px;
    .static-block-text {
      @include decorative-regular-sizeM;
      color: $grey_secondary-text;
    }

    .static-block-heading {
      margin: 36px 0 40px;
      @include decorative-regular-sizeXL;
      color: $blue_main-500;
    }

    .static-block-button {
      width: fit-content;
      margin-top: 40px;
    }
  }

  .static-btn {
    width: 316px;
  }

  .static-heading {
    margin: 120px 0 32px;
  }

  .static-stat-line {
    @include flex-row;
    padding: 0;
    margin: 120px 0;
    column-gap: 40px;
  }
}