@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants';

.main {
  @include flex-column-center;

  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: $Z-IND-MODAL-OVERLAY;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  flex-grow: 1;

  background-color: $black_main-text-50;
}

.container {
  position: relative;
  background-color: $white_BG;
  border-radius: 20px;
  padding: 48px 32px 32px;
  margin: 10px;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
}
