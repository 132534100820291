@import 'src/styles/constants';

$BUTTON_MARGIN_TOP: 12px;

.wrapperChanel {
  display: grid;
  overflow: hidden;
  height: 100%;
  grid-template-rows: auto 1fr auto;
}

.unblock{
  width: fit-content;
  margin-top: $BUTTON_MARGIN_TOP;
  justify-self: center;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .wrapperChanel {
    height: inherit;
    max-height: inherit;
    transform: unset;
  }
}

