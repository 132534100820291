@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.notFound {
  margin-top: 40px;
}

.foundPostsCount {
  color: $grey_caption-disabled-text;
  margin: 0 10px;
}

.breakableTitle {
  word-break: break-word;
}

.otherCityIcon {
  width: 184px;
  height: 184px;
  margin: 28px auto 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .title {
    margin-bottom: 40px;
  }

  .otherCityIcon {
    width: 220px;
    height: 220px;
    margin: 40px auto 32px;
  }
}