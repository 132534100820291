@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.socials {
  @include flex-column-center;
  gap: 16px;
  width: 100%;
}

.socialIcons {
  @include flex-row-center;
  gap: 16px;
  padding-top: 4px;
  padding-bottom: 12px;
}

.dividerHeader {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.dividerText {
  max-width: fit-content;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $grey_main-BG-stroke;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .singleSocial {
    padding: 10px;
  }
}
