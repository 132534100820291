@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

.main {
  @include flex-row;
  @extend .label-16;

  padding: 4px 4px 4px 8px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  height: $HEADER_LOGO_HEIGHT;
  margin: 8px 0;

  column-gap: 6px;
  align-items: center;
}

.dark {
  color: $white_BG;

  &:hover {
    background-color: $transparent-hovers-Dark-on-dark;
    color: $blue_main-300;
  }
}

.light {
  color: $black_main-text;

  &:hover {
    background-color: $transparent-hovers-on-white;
    color: $purple_hover;
  }
}

.selectedText {
  text-transform: uppercase;
}

.chevron {
  @include flex-row;
}