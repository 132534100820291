@import 'src/styles/constants';
@import 'src/styles/mixin';

.modalContainer {
  width: 100%;
}

.buttonColumn {
  @include flex-column;

  gap: 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .modalContainer {
    width: 366px;
  }
}
