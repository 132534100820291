@import 'src/styles/mixin';
@import 'src/styles/typography';

.container {
  @include flex-column;

  gap: 12px;
}

.header {
  justify-content: space-between;
  padding: 8px 12px 0 0;
  gap: 12px;

  @include flex-row;
}

.search {
  padding-bottom: 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .search {
    width: 50%;
    padding-bottom: 0;
  }
}