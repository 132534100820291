@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/typography';
@import 'src/styles/constants';

.mainPostOwner{
  @include flex-column;

  margin-top: -16px;
  gap: 28px;
}

.postOwnerActionButtons{
  @include flex-column;

  gap: 16px;
}

.postActionDescription, .moderationDescription {
  align-items: center;
  align-self: stretch;
  justify-content: center; 
  padding: 16px;
  border-radius: 8px;
  color: $black_main-text;
}

.postActionDescription {
  background-color: $grey_main-BG-stroke;
}

.moderationDescription {
  @include flex-row;

  align-items: flex-start;
  margin-bottom: 16px;
  background-color: $orange_rating-300;
  gap: 8px;
}

.statisticsSection{
  @include flex-column;

  gap: 12px;
}

.statisticLine{
  display: grid;
  grid-template-columns: 1.2fr 2fr;
}

.statisticKey {
  color: $grey_caption-disabled-text;
}

.statisticValue{
  @include flex-row;

  color: $grey_secondary-text;
  gap: 4px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .mainPostOwner{
    margin-top: 16px;
  }
}
