@import 'src/styles/typography.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/mixin.scss';

.swiper {
  .slide {
    height: auto;
  }
}

.item {
  height: 100%;
}

.itemTitle {
  @include decorative-medium-sizeL();
}

.itemText {
  @extend .bodySizeL;

  color: $grey_secondary-text;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }

  .column {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  .item {
    @include flex-column;

    height: fit-content;
    padding: 32px;
    row-gap: 12px;
    margin: 0;
  }
}