@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main {
  @include flex-column-center;
  max-width: 448px;
  text-align: center;

  h2 {
    margin-bottom: 12px;
  }
}

.search {
  margin: 82px auto 24px;

  svg {
    margin-bottom: 20px;
  }
}

.btn {
  margin-top: 20px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    margin: 0 auto;
  }
  .favorite {
    margin: 136px auto 24px;
  
    svg {
      margin-bottom: 24px;
    }
  }

}