@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';

.list {
  @include flex-column;
  row-gap: 20px;
  margin: 28px 0 60px;
}

.itemContent {
  @include flex-column;
  row-gap: 8px;
  padding: 16px 4px 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    .item {
      padding: 0;

      img {
        padding: 0;
        margin: 0;
        width: 100%;
      }
    }

    .itemContent {
      padding: 16px 24px 32px 24px;
    }
  }

}