@import '../../styles/mixin.scss';
@import '../../styles/color.scss';

.main {
  @include flex-row-center;
  justify-content: space-between;
  column-gap: 4px;
  padding: 4px 8px;
  border-radius: 6px;
  color: $black_main-text;
  width: fit-content;
}

.success {
  background-color: $green_success-100;
}

.alert {
  background-color: $orange_rating-100;

  .rightIcon {
    margin-left: 4px;
    path {
      stroke: $orange_rating-500;
    }
  }
}

.error {
  background-color: $red_error-100;
}

.pending {
  background-color: $blue_main-100;
}

.photosNumber {
  background-color: rgba($white_BG, 0.2);
}

.disabled {
  background-color: $grey_main-BG-stroke;
  color: $grey_caption-disabled-text;

  svg {
    path {
      stroke: $grey_caption-disabled-text;
    }
  }
}

.leftIcon {
  path {
    stroke: $black_main-text;
  }
}