@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

$CHAT_HEADER: 74px;
$CHAT_INPUT: 76px;

.history {
  @include flex-column;
  max-height: calc(100dvh - $CHAT_HEADER - $CHAT_INPUT - $MOBILE_HEADER_HEIGHT);
  overflow: auto;
  align-self: end;
}

.content {
  @include flex-column;
  height: 100%;
  padding: 20px 16px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .history {
    max-height: calc($MESSENGER_WINDOW_HEIGHT - $CHAT_HEADER - $CHAT_INPUT);
  }
}
