@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/mixin.scss';

$IMAGE_HEIGHT_MOBILE: 254px;

.photo {
  display: flex;
  width: 100%;
  max-width: 450px;
  max-height: $IMAGE_HEIGHT_MOBILE;
  margin: 0 auto;
  object-fit: cover;
  object-position: top;
  border-radius: 12px;
}

.heading {
  margin: 24px 0 16px;
  @include decorative-regular-sizeM();
}

.subheading {
  @extend .bodySizeL;
  color: $grey_secondary-text;
}

.list {
  @include flex-column;
  row-gap: 28px;
  margin-top: 36px;
}

.item {
  @include flex-column;
  row-gap: 16px;
}

.listItemTitle {
  @extend .bodySizeL;
  padding: 7px 12px;
  width: fit-content;
  color: $blue_main-500;
  border-radius: 99px;
  border: 1px solid $blue_main-500;
}

.listItemText {
  @extend .bodySizeM;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .heading {
    @include decorative-regular-sizeXL();
    margin: 0 0 32px 0;
  }

  .headerWrapper {
    @include flex-row;
    flex-direction: row-reverse;
    column-gap: 40px;
    padding: 60px;

    .photo, .content {
      flex-basis: 50%;
    }
  }

  .photo {
    max-width: 50%;
    height: 100%;
    max-height: 100%;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }

  .item {
    grid-column: 2 / 10;
  }
}