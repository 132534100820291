@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';

.layout {
  @include flex-column;

  .scrollUp {
    bottom: 72px;
  }
}

.content {
  margin-top: 24px;
  white-space: pre-line;

  h2 {
    margin: 28px 0 20px;
  }

  h3, h4 {
    margin: 20px 0 12px;
  }

  section {
    @include flex-column;
  }

  p,
  ul {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  p {
    @include bodySizeM;

    &.label {
      @extend .label-20;

      margin-top: 8px;
      margin-bottom: 12px;
    }
  }

  p,
  a {
    color: $black_main-text;
  }

  a {
    word-break: break-all;
  }

  ul,
  li {
    list-style: disc;
    list-style-type: disc;
  }

  ul {
    padding-left: 20px;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .button {
    display: inline;
    color: $blue_main-500;
    text-decoration: none;
    font-size: inherit;
  }
}

.pageTitle {
  margin-top: 28px;
  word-break: break-all;
}

.imprint {
  p {
    span {
      display: block;
    }
  }
}

.table {
  width: 100%;
  overflow-x: auto;
}

.tableBtn {
  justify-content: flex-start;
}

.tableBtnCollapse {
  svg {
    transform: rotate(180deg);
  }
}

.tableWrapper {
  @include flex-column;
  row-gap: 28px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .layout {
    display: grid;
    grid-template-columns: 3fr 7fr 2fr;
    padding: 82px 0;
    gap: 40px;

    .safetyLayout {
      grid-column: 2/4;
    }

    .scrollUp {
      height: fit-content;
      margin-top: auto;
      grid-column: 3/4;
    }
  }

  .content {
    border-radius: 20px;
    padding: 28px 32px 32px 32px;
    background-color: $white_BG;
  }

  .pageTitle {
    margin-top: 0;
  }

  .menu {
    margin-top: 17px;
  }
}
