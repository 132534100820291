@import '../../styles/color';
@import '../../styles/mixin';

.emptyAvatar {
  @include flex-row-center;

  border-radius: 50%;
  background-color: $black_main-text;
  color: $white_BG;
}

.defaultAvatar {
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.round{
  overflow: hidden;
  border-radius: 50%;

  img {
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
  }
}

.xtiny{
  width: 32px;
  height: 32px;
  font-size: 22px;
}

.tiny {
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  font-size: 38px;
}

.xsmall {
  width: 80px;
  height: 80px;
  font-size: 52px;
}

.small{
  width: 120px;
  height: 120px;
  font-size: 64px;
}
