@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

$RADIO_ITEM_PADDING_RIGHT: 24px;

.radioBtnContainer {
  @include flex-column;
  gap: 12px;
}

.fieldRadioBtn {
  gap: 4px;
  max-width: 306px;

  & p {
    margin-left: $RADIO_ITEM_PADDING_RIGHT;
  }
}

.fieldInput {
  position: absolute;
  left: -9999px;

  &:hover + .label::before {
    border-color: $purple_hover;
  }

  &:checked {
    + .label {
      &::before {
        border: 1px solid $blue_main-500;
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:hover + .label {
      &::before {
        border-color: $purple_hover;
      }

      &::after {
        background-color: $purple_hover;
      }
    }
  }

  &:not(:checked) + .label:after {
    opacity: 0;
    transform: scale(0);
  }

  &:focus,
  &:active {
    &.error {
      border-color: $red_error-500;
      outline-color: $red_error-500;
    }
  }
}

.label {
  position: relative;
  padding-left: $RADIO_ITEM_PADDING_RIGHT;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;

  &::before,
  &::after {
    margin-top: 4px;
    content: '';
    position: absolute;
    border-radius: 50%;
    @include transition(all, 0.2s, ease-in-out);
  }

  &::before {
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $grey_caption-disabled-text;
    background: $white_BG;
  }

  &::after {
    width: 8px;
    height: 8px;
    top: 4px;
    left: 4px;
    background: $blue_main-500;
  }
}
