@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/responsive.scss';

.modalContainer {
  width: 100%;
  height: 100%;
  @include mobile-paddings;
}

.formContainer {
  @include flex-column;

  gap: 20px;
  height: 100%;
}

.buttonRow {
  @include flex-column;

  gap: 12px;
}

.shortenOfferContainer {
  padding: 8px;
}

.formRadio {
  @include bodySizeM;
  gap: 16px;
  color: $black_main-text;
  flex-grow: 1;
}

.successContainer {
  max-width: 430px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .modalContainer {
    width: 416px;
    padding: 0;
  }

  .buttonRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

}
