@import '../../../../styles/color';
@import '../../../../styles/mixin';
@import '../../../../styles/typography';

.mainPostForm {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.formBody {
  align-items: flex-start;
  padding: 24px 16px;
  border-radius: 12px;
  background-color: $white_BG;
  gap: 28px;

  @include flex-column;
}

.formSection {
  @include flex-column;

  width: 100%;
  height: 100%;
  gap: 20px;
}

.mapField {
  height: 100%;
}

.postFieldLabel {
  @include label-14;

  color: $grey_secondary-text;
}

.subfields {
  @include flex-column;

  gap: 20px;
}

.buttonBlock {
  @include flex-row;

  gap: 16px;
  grid-column: 2 / 5;
}

.buttonBlockWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 3fr;
}

.mobileHeaderContainer {
  @include flex-row;

  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  gap: 12px;
}

.formContainer {
  height: 100%;
}

.mobileFormContainer {
  @include flex-column;

  height: 100%;
  gap: 12px;
}

.required {
  color: $blue_main-500;
}

.successContainer {
  width: 100%;
  max-width: 430px;
}

.successButtons {
  @include flex-column;

  gap: 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .formSection {
    gap: 24px;
  }

  .postField {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 3fr;
  }

  .toggle {
    @include flex-row;

      gap: 8px;
  }

  .postFieldLabel {
    @include bodySizeM;
  }

  .halfWidthFieldLayout {
    display: grid;
    width: 100%;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .subfields {
    @include flex-row;

    align-items: flex-start;
    gap: 32px;
  }

  .divider{
    padding-top: 28px;
    border-top: 2px solid $grey_main-BG-stroke;
  }
}