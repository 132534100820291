@import './constants';

.offer-page-slider {
  width: 100%;
  min-height: $OFFER-PAGE-SLIDER-HEIGHT;
  max-height: $OFFER-PAGE-SLIDER-HEIGHT-DESKTOP;
  aspect-ratio: 3/2;
  cursor: pointer;

  .swiper-button-next, .swiper-button-prev {
    @include flex-row-center;
  }
}

.fullscreen-slider {
  position: relative;
  width: 100%;
  height: calc(100vh - $OFFER-PAGE-FULLSCREEN_SLIDER_TOP_PADDING_MOBILE);

  .swiper-button-next, .swiper-button-prev {
    top: auto;
    bottom: 18px;
  }
}

.offer-page-slider, .fullscreen-slider {

  .swiper-button-next, .swiper-button-prev {
    background-color: $transparent-hovers-light-on-dark;
    border-radius: 50%;
    color: $black_main-text;

    width: 44px;
    height: 44px;

    @include flex-row-center;

    box-shadow: 0px 4px 4px 0px rgba($shadow-color-1, 0.12), 0px -1px 4px 0px rgba($shadow-color-2, 0.12);

    &:after {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 32px;
      height: 32px;
    }
  }

  .swiper-button-next {
    &:after {
      background-image: url('../../public/icons/icons-32/next.svg');
    }
  }

  .swiper-button-prev {
    &:after {
      background-image: url('../../public/icons/icons-32/prev.svg');
    }
  }

  .swiper-slide {
    @include flex-row-center;
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }
}

.fullscreen-thumbs-slider {
  .swiper-wrapper {
    justify-content: center;
  }
}

.offer-page-thumbs-slider {
  margin-top: 16px;
  height: $OFFER-PAGE-THUMBNAILS-SLIDER-HEIGHT;
  max-width: 100%;

  .swiper-wrapper {
    gap: 8px;

    .swiper-slide {
      max-width: $OFFER-PAGE-THUMBNAILS-SLIDE-WIDTH;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;
      border: 2px solid $grey_disabled-BG;
      margin-right: 0 !important;
      height: 64px;

      img {
        max-width: 100%;
        opacity: 0.6;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      &.swiper-slide-thumb-active img {
        opacity: 1;
      }
    }
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .offer-page-slider {
    height: $OFFER-PAGE-SLIDER-HEIGHT-DESKTOP;
    border-radius: 12px;
  }

  .swiper-button-next, .swiper-button-prev {
    background-color: $white_BG;
  }

  .fullscreen-slider {
    height: calc(100vh - $OFFER-PAGE-FULLSCREEN_SLIDER_TOP_PADDING - $OFFER-PAGE-FULLSCREEN_SLIDER_BOTTOM_PADDING);

    .swiper-button-next, .swiper-button-prev {
      top: 50%;
      bottom: auto;
    }
  }
}
