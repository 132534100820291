@import '../../../styles/color';
@import '../../../styles/mixin';
@import '../../../styles/typography.scss';

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .priceField {
    width: 198px;
  }

  .fieldError{
    overflow: visible;
    text-wrap: nowrap;
  }
}
