@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants';

.iconWrap {
  @include flex-row;

  align-items: center;
  color: $grey_caption-disabled-text;
  column-gap: 4px;

  @include svg-stroke-current-color;

  button {
    color: $grey_caption-disabled-text;
  }
}

.isOneRow{
  @include flex-row;

  gap: 12px;
}

.severalRows {
  @include flex-column;

  gap: 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .iconWrap {
    color: $black_main-text;

    button {
      color: $black_main-text;
    }
  }
}