.swiper-custom-pagination {
  margin-top: 16px;
  @include flex-row-center;
}

.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background-color: $white_BG;
  border: 2px solid $blue_main-500;
}

.swiper-pagination-bullet-active {
  background-color: $blue_main-500;
  width: 20px;
  height: 20px;
}