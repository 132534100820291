@import 'src/styles/mixin';
@import 'src/styles/color';

.userData{
  display: flex;
  padding-bottom: 8px;
  gap: 16px;
}

.registrationDate{
  display: flex;
  gap: 4px;
}

.userName {
  color: $black_main-text;
}

.userBadgeBlock {
  margin-top: 8px;
  margin-bottom: 12px;
}
