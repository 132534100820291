@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';

.headerGroupDate {
  @include flex-row-center;

  flex-wrap: wrap;
  margin-bottom: 4px;

  &:not(:first-child) {
    margin-top: 20px;
  }

  .dateLabel {
    padding: 6px 12px;
    border-radius: 12px;
    background: $gray-dark-light;
  }
}