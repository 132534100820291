@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main {
  @include flex-column;
  gap: 20px;
  background-color: $white_BG;
  width: 100%;
  border-radius: 12px;
  padding: 12px 0;

}

.wrapperInfo {
  @include flex-column;
  padding-left: 20px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    gap: 24px;
    padding: 24px 0;
  }

  .wrapperInfo {
    padding-left: 28px;
  }
}
