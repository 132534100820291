@import '../../../styles/mixin.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/color.scss';

.bodyPrice {
  @include flex-row;
  width: 100%;
  align-items: center;

  .delivery {
    margin-left: 8px;
  }

  .active {
    color: $black_main-text;
  }

  .disabled {
    color: $grey_caption-disabled-text;
  }
}
