@import 'src/styles/static.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';

.photos {
  width: calc(100% + $BASE_PADDING * 2);
  margin-left: -16px;
}

.subheading {
  @include desktopBodySize22;
}

.buttons {
  @include flex-column;
  row-gap: 12px;
  margin-top: 16px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .photos {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
  }

  .subheading {
    @include desktopBodySize32;
  }

  .buttons {
    @include flex-row;
    column-gap: 24px;
    margin-top: 20px;

    a {
      width: 312px;
    }
  }
}