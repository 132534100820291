@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.main {
  padding: 16px;
}

.list {
  @include flex-column;
  row-gap: 12px;
}

.title {
  @include decorative-medium-sizeL();
  color: $blue_main-500;
  margin: 20px 0 24px;
}

.offer {
  @include flex-row;
  align-items: flex-start;
  column-gap: 8px;
  color: $black_main-text;
  @include svg-stroke-current-color;
}

.offerText {
  @extend .bodySizeL;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    padding: 60px;
  }  

  .title {
    @include decorative-medium-sizeXL();
    color: $blue_main-500;
    margin: 36px 0px 40px 0;
  }

  .list {
    @include flex-row;
    flex-wrap: wrap;
    gap: 40px;
  }

  .offer {
    flex-basis: calc(50% - 40px);
    column-gap: 24px;
    align-items: center;

    &:last-child {
      flex-basis: 100%;
    }
  }

  .offerText {
    @include decorative-regular-sizeS;
  }
}