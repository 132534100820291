@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.offerContainer {
  background-color: $white_BG;
}

.postContent, .postHeader, .customerActionsButtons {
  @include flex-column;

  gap: 20px;
}

.postContent {
  padding: 0 $BASE_PADDING 20px;
}

.postHeader, .customerActionsButtons {
  gap: 12px;
}