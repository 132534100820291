@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.main {
  @include flex-column;

  gap: 16px;
}

.searchBar {
  @include flex-row-center;

  width: 100%;
  padding: 16px 0;
  gap: 12px;
}

.resultsWrapper {
  max-width: 100%;
}

.sticky {
  position: sticky;
  top: $HEADER_HEIGHT;
  z-index: $Z-IND-STICKY;
  background-color: $grey_disabled-BG;
}

.leftSearchBar {
  @include flex-row;

  height: 40px;
  gap: 16px;
  grid-column: 1 / 10;
  margin-right: 24px;
}

.searchWrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.filterPanelWrapper {
  grid-column: span 3;
  max-width: 100%;
}

.citySelectLabel {
  @include flex-column;

  align-items: flex-start;
}

.secondaryCityLabel {
  color: $grey_caption-disabled-text;
}

.searchInput {
  @include flex-column;

  width: 100%;
  gap: 4px;
  position: relative;
}

.citySelectorButton {
  @include flex-row;

  grid-column: 10 / 12;
  align-items: center;

  &:hover{
    .secondaryCityLabel {
      color: inherit;
    }
  }
}

.filtersButtonsRow {
  @include flex-row;

  justify-content: space-between;
  gap: 12px;
}

.mobileFilterChips {
  @include flex-row;

  gap: 8px;
  overflow: auto;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    display: unset;
  }

  .searchBar {
    max-width: $BASE_WIDTH;
    display: grid;
    gap: 28px;
    grid-template-columns: repeat(12, 1fr);
    padding: 28px 0;
  }

  .searchWrapper {
    grid-template-columns: repeat(12, 1fr);
  }

  .resultsWrapper {
    grid-column: span 9;
    margin-left: 40px;
  }
}
