@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.main {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 16px;
  background-color: $white_BG;
  border-radius: 5px 0px 0px 5px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    border-right: 1px solid $gray-dark-light;
    width: 334px;
    transform: none;
  }
}
