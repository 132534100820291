@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$HEADER_BUTTON_MIN_WIDTH: 128px;
$HEADER_BUTTON_MIN_WIDTH_RU: 182px;
$HEADER_PROFILE_DROPDOWN_LEFT_OFFSET: $DROPDOWN_OPTION_H_PADDING - ($HEADER_USER_AVATAR_SIZE - $DROPDOWN_OPTION_ICON_SIZE) / 2;

$DROPDOWN_MIN_WIDTH: 211px;

.main {
  @include flex-row-center;

  background-color: $black_main-text;
  color: $white_BG;
  width: 100%;
}

.profileWrapper {
  position: relative;
}

.mobileHeader {
  z-index: $Z-IND-STICKY;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px $BASE_PADDING;
  border-top: 1px solid $grey_main-BG-stroke;
  background-color: $white_BG;
}

.mobileTabBar {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.logo {
  padding: 8px 48px 8px 0px;

  svg {
    display: block;
    height: $HEADER_LOGO_HEIGHT;
    cursor: pointer;
  }
}

.sticky {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: $Z-IND-DROPDOWN;
}

.inner {
  @include inner;
  @include flex-row-center;

  justify-content: space-between;
  padding: 0 $BASE_PADDING;
  height: $HEADER_HEIGHT;
}

.item {
  @include flex-row;

  align-items: center;
}

.nav {
  @include flex-row-center;

  height: 100%;
}

.profileWrap {
  @include flex-row-center;

  height: $HEADER_HEIGHT;
}

.profile {
  @include flex-row-center;

  column-gap: 8px;

  position: relative;
  height: 100%;
}

.profileNav {
  @include box-shadow($shadow-color-1);

  z-index: $Z-IND-DROPDOWN;
  left: -$HEADER_PROFILE_DROPDOWN_LEFT_OFFSET;
  top: $HEADER_HEIGHT;
  position: absolute;
  min-width: calc(100% + $HEADER_PROFILE_DROPDOWN_LEFT_OFFSET * 2);
  margin: 0;
  padding: 0;

  min-width: $DROPDOWN_MIN_WIDTH;
}

.langSelectWrapper {
  @include flex-row-center;

  margin-left: 24px;
}

.buttons {
  @include flex-row;

  a {
    min-width: $HEADER_BUTTON_MIN_WIDTH;
  }

  &.ru a {
    min-width: $HEADER_BUTTON_MIN_WIDTH_RU;
  }
}

.authBtn,
.newItemBtn {
  flex: 1;
  width: initial;
  white-space: nowrap;
}

.authBtn {
  margin-right: 12px;
  background-color: $white_BG;
  color: $black_main-text;

  &:hover {
    color: $white_BG;
  }
}

.newItemBtn,
.loader,
.chevron {
  color: $white_BG;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .inner {
    padding: 0;
  }
}
