@import '../../../../styles/color.scss';
@import '../../../../styles/mixin.scss';

@import '../../../../routes/profile/profileLayout.module.scss';

.title {
  @include flex-row;

  margin: 12px 0;
  column-gap: 12px;
}

.settingSections {
  @include flex-column;

  gap: 24px;
  margin-bottom: 24px;
}

.input {
  & input {
    background-color: $grey_main-BG-stroke;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .title {
    margin-bottom: $margin-bottom-profile-title-section;
  }

  .settingSections {
    margin-bottom: 0;
  }

  .input {
    & input {
      background-color: $white_BG;
    }
  }
}