@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr auto;
  column-gap: 32px;
  row-gap: 12px;
}

.toggleLabel {
  grid-column: 1/2;
  grid-row: 1/2;
}

.toggleButton {
  grid-column: 2/3;
  grid-row: 1/2;
}

.toggleDescription {
  grid-column: 1/3;
  grid-row: 2/3;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .toggleDescription {
    grid-column: 1/2;
  }
}