@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main {
  @include flex-column;
  gap: 28px;
  width: 100%;
}

.leftSide {
  margin-right: 0;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }

  .leftSide {
    grid-column: 1 / 10;
    margin-right: 24px;
  }

  .rightSide {
    grid-column: 10 / 13;
  }
}