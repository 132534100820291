@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';
@import 'src/styles/constants';

$ORIGINAL_TEXT_PADDING_MOBILE: 12px;
$ORIGINAL_TEXT_PADDING_DESKTOP: 20px;

.field {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.fieldData {
  @include flex-row;

  overflow: hidden;
  color: $black_main-text;

  div {
    display: inline-block;
    margin-right: 8px;
  }
}

.descriptionField, .origContainer {
  gap: 8px;
  grid-template-columns: 1fr; // mobile

  .fieldData {
    white-space: pre-wrap;
  }
}

.hideText .fieldData {
  overflow: hidden;
  max-height: 254px;
}

.locationField {
  @include flex-column;

  gap: 12px;
}

.fieldName {
  display: flex;
  color: $grey_caption-disabled-text;

  span {
    @include flex-row;

    margin-left: 4px;
  }
}

.sectionMain {
  position: relative;

  .sectionHeading {
    margin-bottom: 16px;
  }
}

.sectionFields {
  @include flex-column;

  gap: 16px;
}

.mapWrapper {
  margin-top: 36px;
  grid-column: 1 / 8;
}

.buttonWrapper {
  margin: auto;
}

.iconRotate {
  svg {
    transform: rotate(-180deg);
    transition: 0.3s all ease;
  }
}

.reviewsMain {
  grid-template-columns: 1fr; // mobile

  h3 {
    margin-bottom: 20px;
  }
}

.showAllBtn {
  position: absolute;
  right: 0;
  bottom: 0;
}

.descriptionButtons {
  @include flex-column;

  align-items: flex-end;
  gap: 10px;
}

.translatedLabel {
  display: inline;
  align-self: center;
  color: $grey_caption-disabled-text;
}

.origContainer {
  position: relative;
  display: grid;
  width: 100%;
  min-height: $REGULAR_BUTTON_HEIGHT;
  margin-top: 20px;
  gap: 0;
}

.origTitle {
  padding: 10px 0 14px;
}

.seeOriginalBtn {
  position: absolute;
  z-index: $Z-IND-ONTOP;
  bottom: 0;
  width: 100%;
}

.originalTextContainer {
  @include flex-column;

  align-items: flex-start;
  padding: $ORIGINAL_TEXT_PADDING_MOBILE;
  padding-bottom: 76px;
  border-radius: 12px;
  margin: -$ORIGINAL_TEXT_PADDING_MOBILE;
  background: $blue_main-50;
  gap: 8px;
  white-space: pre-wrap;
}

.skeletonContainer {
  padding-bottom: calc($REGULAR_BUTTON_HEIGHT + $ORIGINAL_TEXT_PADDING_MOBILE);
  border-radius: 12px;
  margin: -$ORIGINAL_TEXT_PADDING_MOBILE;
  background-color: $grey_disabled_BG;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .field,
  .descriptionField, .origContainer {
    grid-template-columns: 2fr 5fr;
  }

  .descriptionField {
    align-items: baseline;
    gap: 0;
  }

  .hideText .fieldData {
    max-height: 820px;
  }

  .locationField {
    display: grid;
    gap: 0;
    grid-template-columns: 2fr 3fr 2fr;
  }

  .buttonWrapper {
    margin: unset;
    margin-left: auto;
  }

  .sectionMain {
    .sectionHeading {
      margin-bottom: 24px;
    }
  }

  .reviewsMain {
    grid-template-columns: 2fr 5fr;

    button {
      margin-top: 24px;
      margin-left: auto;
      grid-column:  2 / 3;
    }
  }

  .descriptionButtons {
    gap: 16px;
  }

  .originalTextContainer {
    padding: $ORIGINAL_TEXT_PADDING_DESKTOP;
    margin: -$ORIGINAL_TEXT_PADDING_DESKTOP;
  }

  .seeOriginalBtn {
    top: 0;
    right: 0;
    bottom: unset;
    width: unset;
  }

  .skeletonContainer {
    padding-bottom: unset;
    margin: -$ORIGINAL_TEXT_PADDING_DESKTOP;
  }
}