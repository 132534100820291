@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/typography.scss';

.main {
  background-color: $white_BG;
  border-radius: 12px;
  padding: 20px; 
}

.content{
  @include flex-row;
  flex-direction: row-reverse;
  column-gap: 12px;
  justify-content: space-between;
}

.avatarWithControl{
  position: relative;
  height: fit-content;
  margin-right: 20px;
}


.userInfo{
  @include flex-column;
  gap: 8px;
}

.followers{
  display: flex;
  gap: 8px;
  margin-top: 8px;

  div {
    flex: 0 0 50%;
  }
}

.userName {
  max-width: 178px;
  @include text-overflow-ellipsis(1);
}

.avatarDropdown{
  width: 186px;
}

.dateWrapper {
  @include flex-row;
  @extend .supportive-14;
  color: $grey_secondary-text;
}

.cameraControl{
  padding: 0;
  position: absolute;
  right: -20px;
  bottom: 0px;
  width: 40px;
  height: 40px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .dateWrapper {
    @include bodySizeM;
  }

  .content{
    @include flex-column;
  }

  .userName {
    max-width: unset;
  }

  .followers{
    div {
      flex: none;
    }
  }

  .cameraControl{
    left: 88px;
    bottom: 8px;
    width: 40px;
    height: 40px;
    padding: 7px 9px; 
  }

  .avatarWithControl{
    position: relative;
    margin-bottom: 12px;
    height: fit-content;
  }
  }