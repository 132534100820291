@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/mixin.scss';

$margin-bottom-profile-title-section: 24px;
$h1-title-height: 35.2px;

.profileLayout {
  margin-top: 12px;
}

.profileHeading {
  margin-bottom: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .profileLayout {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 40px;
  }

  .profileHeading {
    margin-bottom: 36px;
  }
}









