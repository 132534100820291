@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$MODAL_CONTAINER_WIDTH: 430px;

.main {
  max-width: $MODAL_CONTAINER_WIDTH;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    width: $MODAL_CONTAINER_WIDTH;
  }
}
