.main {
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.cover {
  object-fit: cover;
}

.contain {
  object-fit: contain;
}