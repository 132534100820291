@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.skeletonList {
  display: grid;
  margin: 12px 0 28px;
  grid-template-columns: 1fr;
}

.horizontal.skeletonList {
  gap: 24px;
  grid-template-columns: 1fr;
}

.wireframe {
  @include flex-column;

  width: 100%;
  height: 270px;
  gap: 8px;
}

.wireframeBody {
  overflow: hidden;
  width: 100%;
  height: 178px;
  border-radius: 8px;
  background-color: $grey_main-BG-stroke;
}

.horizontal.wireframe {
  display: grid;
  height: 100%;
  align-items: start;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.horizontal .wireframeBody {
  height: 98px;
}

.wireframeInfo {
  width: 100%;
}

.tiles {
  height: 100px;
}

@keyframes move-left-to-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes move-ablolute-left-to-right {
  0% {
    left: 0;
  }

  100% {
    left: 50%;
  }
}


.skeletonLoaderGradient {
  display: block;
  width: 50%;
  height: 100%;
  animation: move-left-to-right 2s linear infinite;
  background: linear-gradient(90deg, $grey_main-BG-stroke 0%, $white_BG 20%, $grey_main-BG-stroke 100%);
}

.relativeContainer {
  position: relative;
}

.skeletonAboveImageLoader {
  position: absolute;
  display: block;
  width: 50px;
  height: 100%;
  animation: move-ablolute-left-to-right 2s linear infinite;
  background: linear-gradient(to right, rgba($white_BG, 0.6), rgba($white_BG,0));
}

@media screen and (min-width: $XS-MOBILE-BREAKPOINT) {
  .skeletonList {
    gap: 16px 24px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .skeletonList {
    grid-template-columns: repeat(4, 1fr);
  }

  .horizontal.wireframe {
    @include flex-row;
  }

  .horizontal .wireframeBody {
    height: 188px;
  }
}
