@import '../../styles/typography.scss';

.main {
  display: inline-flex;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: left;

  &:hover {
    color: $purple_hover;
  }

  &.selected {
    color: $purple_hover;
  }
}
