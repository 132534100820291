@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants.scss';

.showcaseContainer {
  position: relative;
}

.backgroundImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.learnMoreBtn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-wrap: nowrap;
}

.sponsoredButton {
  position: absolute;
  right: 12px;
  top: 12px;

  border-color: transparent;
  border-radius: 99px;
  background-color: rgba(44, 44, 44, 0.80);
  color: $white_BG;

  @include flex-row;
  gap: 4px;
  height: 24px;
  padding: 0px 4px 0px 8px;
  align-items: center;
}

.vertical {
  width: 100%;
  aspect-ratio: 7/10;
  margin-top: $VERTICAL_SHOWCASE_TOP_PADDING;


  .learnMoreBtn {
    bottom: 36px;
  }
}

.horizontal {
  width: 100%;
  height: 188px;
  margin-top: 24px;

  .learnMoreBtn {
    bottom: 14px;
  }
}

.mobile-big {
  width: 100%;
  max-width: 320px;
  aspect-ratio: 10/11;
  margin: auto;
  margin-top: 20px;

  .learnMoreBtn {
    bottom: 16px;
  }
}

.mobile-small {
  width: 100%;
  max-width: 328px;
  aspect-ratio: 19/10;
  margin: auto;
  margin-top: 16px;
  padding-bottom: 12px;

  .learnMoreBtn {
    bottom: 24px;
    right: 12px;
    left: unset;
    transform: none;
  }

  .sponsoredButton {
    left: 12px;
    right: unset;
  }
}