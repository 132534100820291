@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.modalLayout {
  @include flex-column-center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: $Z-IND-MODAL-OVERLAY;
  width: 100vw;
  height: 100%;
  overflow: auto;
  background-color: $white_BG;
}

.mobileModalContainer {
  // TODO: to be removed
  @include inner;

  position: relative;
  height: 100%;
  z-index: $Z-IND-MODAL-CONTENT;
}

.heading {
  @include flex-row;

  gap: 12px;
  justify-content: space-between;
}

.subheading {
  color: $grey_caption-disabled-text;
}

.contentContainer {
  @include flex-column;

  height: 100%;
  gap: 16px;
}

.contentBody {
  flex-grow: 1;
  margin-bottom: 24px;
}

.close {
  margin-left: auto;
}
