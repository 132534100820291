@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';
@import 'src/styles/common';

$extraPaddingOnHover: 24px;
$containerHeight: 188px;
$buttonsBlockHeight: 40px;
$gap: 12px;
$buttonBottomGap: $MOBILE_HEADER_HEIGHT + $gap;
$listHeader: 24px + 29px + 36px;
$myOffersListHeader: ($MOBILE_HEADER_HEIGHT * 2 + $listHeader);

.myOfferListContainer{
  @include flex-column;

  height: 100%;
  gap: 20px;

  .newItemBtn {
    bottom: $buttonBottomGap;
  }
}

.oneItemContainer {
  min-height: calc(100vh - $myOffersListHeader);

  & .wrapper {
    @include flex-column;

    height: 100%;
    flex-grow: 1;
    justify-content: space-between;
  }
}

.loaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.sortDropdown {
  justify-content: left;
}

.content {
  @include flex-column;

  overflow: hidden;
  max-width: 100%;
  padding: 12px 12px 12px 0;
  row-gap: 4px;
}

.container {
  @include flex-row;

  position: relative;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: $white_BG;
  gap: 12px;

  &:hover {
    background-color: $grey_main-BG-stroke;
  }
}

.cardWrapper {
  position: relative;
  width: 100%;
}

.buttonsBlock {
  @include flex-column;

  height: $buttonsBlockHeight;

  .btn {
    width: 100%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.buttonsRow {
  @include flex-row;

  row-gap: 8px;

  button {
    justify-content: center;
  }
}

.rightStack {
  @include flex-column;

  row-gap: 24px;
}

.dropdown {
  width: 172px;
}

.location {
  span {
    // TODO: get rid of it
    @include bodySizeM;
  }
}

.labelWrapper {
  margin-top: 24px;
}

.noPosts {
  margin-bottom: 24px;
}

.ellipsisButton {
  button {
    width: 40px;
    font-size: 12px;
  }
}

@media (min-width: $DESKTOP-BREAKPOINT) {
  .container {
    position: relative;
    height: $containerHeight;
    margin-bottom: 24px;
    column-gap: 24px;

    &::before {
      position: absolute;
      width: calc(100% + $extraPaddingOnHover);
      height: calc(100% + $extraPaddingOnHover);
      border-radius: 12px;
      margin: -12px;
      content: "";
    }

    &:hover {
      background-color: unset;

      &::before {
        background-color: $grey_main-BG-stroke;
      }
    }
  }

  .rightStack {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 186px;
  }

  .content {
    position: relative;
    display: grid;
    width: 100%;
    padding: 20px 20px 20px 0;
    column-gap: 40px;
    grid-auto-flow: dense;
    grid-template-columns: 2fr 2fr 2fr;
  }
}
