@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

$VISIBLE_CONTENT_HEIGHT: 50vh;

.scrollContent{
  padding-right: 5px;
}

.linkToPolicy{
  padding-top: 12px;
}

.singleToggle{
  padding-top: 12px;
  padding-bottom: 12px;
}

.borderLine{
  border-bottom: 2px solid $grey_main-BG-stroke;
}

.additionalInfo{
  @include flex-column;
  gap: 12px;
}

.learnMore{
  margin-right: auto;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .manageCookie {
    width: 970px;
  }

  .scrollContent{
    overflow: auto;
    max-height: $VISIBLE_CONTENT_HEIGHT;
  }

  .saveAndClose{
    width: 300px;
  }
}
