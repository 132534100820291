@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants.scss';

.main {
  bottom: 72px;
  position: sticky;

  button {
    @include flex-row;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    @include flex-column;
    @include svg-stroke-current-color;

    align-items: flex-end;
    justify-content: flex-end;

    button {
      bottom: 40px;
      color: $blue_main-500;

      &:hover {
        color: $purple_hover;
      }
    }
  }
}
