@import 'src/styles/static.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';

.list {
  @include flex-column;
  row-gap: 12px;
  margin: 44px 0;
}

.column {
  @include flex-column;
  row-gap: 12px;
}

.subheading {
  @extend .bodySizeL;
}

.item {
  @extend .static-block;
  @include flex-column;
  row-gap: 12px;
}

.icon {
  margin: 0 auto;
}

.itemHeading {
  @include decorative-medium-sizeL;
}

.itemText {
  @extend .bodySizeL;
  color: $grey_secondary-text;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .listDesktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    margin: 60px 0 120px;
  }

  .column {
    row-gap: 40px;
  }

  .item {
    padding: 32px;
  }

  .main .heading {
    margin-top: 0;
  }

  .subheading {
    @include decorative-regular-sizeL;
  }
}