@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/static.scss';


.main {
  display: grid;
  gap: 12px;
}

.text {
  @extend .bodySizeL;
}

.item {
  @include flex-row;
  @extend .static-block;
  align-items: flex-start;
  column-gap: 16px;
  padding: 16px;
  border-radius: 20px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    grid-template-columns: 1fr 1fr;
    gap: 20px 40px;
    margin-top: 60px;
  }

  .item {
    align-items: center;
    padding: 16px;
  }
}