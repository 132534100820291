@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$SINGLE_IMAGE_WIDTH: 127px;

.imageBlock {
  @include flex-row;

  gap: 16px;
  flex-wrap: wrap;
}

.singleImageBlock {
  @include flex-column;

  gap: 8px;
  max-width: $SINGLE_IMAGE_WIDTH;
}

.singleImage {
  display: inline-block;
  position: relative;
}

.imageText{
  color: $black_main-text;
}

.image {
  width: $SINGLE_IMAGE_WIDTH;
  height: 91px;
  border: none;
  border-radius: 5px;
  background-color: $grey_main-BG-stroke;

  img {
    object-position: center;
    border-radius: 5px;
  }

  &:hover {
    .addPhotoImage path {
      stroke: $purple_hover;
    }

    .imageText{
      color: $purple_hover;
    }
  }

  &.disabled {
    cursor: auto;

    .addPhotoImage path {
      stroke: $grey_caption-disabled-text;
    }

    .imageText{
      color: $grey_caption-disabled-text;
    }
  }
}

.loader {
  color: $blue_main-500;
  margin: auto;
}

.crossBtn{
  @include flex-row-center;

  position: absolute;
  top: 2px;
  right: 2px;
  border: none;
  border-radius: 4px;
  background: rgba($white_BG, 0.70);
  width: 16px;
  height: 16px;
}
