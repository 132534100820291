@import 'src/styles/typography.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

$FORM_BTN_WIDTH: 208px;

.main {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 1fr;
}

.heading {
  margin-bottom: 20px;
  @include flex-row;
  gap: 12px;
  align-items: center;
}

.group {
  @include flex-column;
  row-gap: 20px;
  margin-bottom: 20px;
}

.policy {
  margin-top: 20px;
  color: $grey_caption-disabled-text;

  a {
    color: $blue_main-500;
  }
}

.successHeading {
  @include flex-row;
  align-items: center;
}

.headerBtn {
  @include flex-row;
  align-items: center;
  @extend .label-16;
  color: $blue_main-500;
  margin-top: 24px;
}

.formBtn {
  margin-top: 20px;
}

.successWrapper {
  max-width: 430px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    margin-top: 44px;
    grid-template-columns: repeat(12, 1fr);
  }

  .heading {
    margin-bottom: 36px;
  }

  .group {
    row-gap: 36px;
  }

  .form {
    grid-column: 1 / 8;
  }

  .formBtn {
    margin-top: 36px;
    width: $FORM_BTN_WIDTH;
    position: static;
  }

  .policy {
    margin-top: 12px;
  }
}
