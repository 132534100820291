@import 'src/styles/mixin';
@import 'src/styles/color';

.main {
  button {
    background-color: rgba($white_BG, 0.7);
    color: $blue_main-500;

    @include svg-stroke-current-color;
  }

  &.transparent {
    button {
      background-color: transparent;
      box-shadow: none;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

