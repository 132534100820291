@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.main {
  top: 100%;
  max-width: 100%;
  max-height: initial;
  border: none;
  box-shadow: none;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    @include box-shadow($shadow-color-1);

    border: 1px solid $grey_main-BG-stroke;
  }
}
