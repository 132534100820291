@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/typography.scss';

.main {
  @include flex-row;

  width: 100%;
  box-shadow: none;

  &,
  .message {
    align-items: center;
  }
}

.upload {
  padding: 4px 0;
}

.upload, .send {
  @include svg-fill-current-color;

  color: $grey_caption-disabled-text;

  &.disabled {
    opacity: 0.5;

    button {
      cursor: default;
    }
  }

  &:hover {
    color: rgba(106, 126, 252, 0.8);
  }
}

.message {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;

  .field {
    width: 100%;

    textarea {
      // TODO: get rid of this
      @include bodySizeM();

      height: 44px;
      min-height: 44px;
      padding-right: 44px;
      outline: 0;
      border-radius: 5px;
      background-color: $white_BG;

      // TODO: use var from color.scss
      border: 2px solid rgba(106, 126, 252, 0.4);

      &:hover {
        border-color: rgba(106, 126, 252, 0.6);
      }

      &:focus {
        border-color: rgba(106, 126, 252, 0.8);
      }
    }
  }
}

.send {
  @include flex-row-center;

  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 6px;
  color: rgba(106, 126, 252, 0.6);

  button:hover {
    background-color: transparent;
  }
}
