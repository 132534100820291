@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants';

.wrapper {
  position: relative;
}

.main {
  @include box-shadow($shadow-color-1);

  position: absolute;
  z-index: $Z-IND-DROPDOWN;
  right: 0;
  overflow: auto;
  width: max-content;
  min-width: 100%;
  max-height: 320px;
  border: 1px solid $grey_main-BG-stroke;
  border-radius: 8px;
  margin-top: 4px;

  &.left {
    right: initial;
    left: 0;
  }

  &.above {
    bottom: 48px;
  }
}

.sections {
  @include flex-column;

  position: relative;
  z-index: $Z-IND-DROPDOWN;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $white_BG;
}

.section {
  @include flex-column;

  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid $grey_main-BG-stroke;

  &:first-child {
    padding-top: 0;
    border-top: none;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.action {
  padding: 8px $DROPDOWN_OPTION_H_PADDING;
  border: none;
  background: none;
  color: $black_main-text;
  text-align: start;

  &:hover {
    background-color: $grey_main-BG-stroke;
  }

  &.selected {
    background-color: $grey_main-BG-stroke;
  }
}

.label {
  @include flex-row;

  gap: 8px;

  .icon {
    margin-top: 2px;
  }

  .text {
    @include flex-column;

    gap: 2px;
  }

  .info {
    color: $grey_caption-disabled-text;
  }
}
