@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.info {
  @include flex-column;

  max-width: 100%;
  row-gap: 4px;
  overflow: hidden;

  .title {
    @include text-overflow-ellipsis(2);

    color: $blue_main-500;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .info {
    row-gap: 8px;
  }
}