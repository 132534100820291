@import 'src/styles/color';
@import 'src/styles/mixin';

.user {
  @include flex-row;
  gap: 4px;
  align-items: center;
}

.userInfo {
  padding: 0 8px;
}

.email {
  color: $grey_secondary-text;
}

.secondaryText {
  margin: 28px auto 0;
  color: $grey_secondary-text;
}