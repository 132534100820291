@import 'src/styles/mixin.scss';

.main {
  @include flex-row-center;
  color: inherit;
  
  .fill, .fill-stroke {
    path {
        fill: currentColor;
      }
    }

  .stroke, .fill-stroke {
    path {
        stroke: currentColor;
      }
    }

}
