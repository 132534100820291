@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$extraPaddingOnHover: 8px;
$imageWidth: 110px;
$offsetFromImage: 8px;

.container {
  @include flex-row;

  justify-content: flex-start;
  gap: $offsetFromImage;
  position: relative;

  &::before {
    content: "";
    height: calc(100% + $extraPaddingOnHover * 2);
    position: absolute;
    top: -$extraPaddingOnHover;
    left: -$extraPaddingOnHover;
    width: calc(100% + ($extraPaddingOnHover * 2));
    border-radius: 12px;
  }

  &.disabledContainer {
    &::before {
      background-color: $grey_disabled-BG;
    }
  }

  &:hover:before{
    background: $grey_main-BG-stroke;
  }
}

.imageBlock {
  position: relative;
  width: $imageWidth;
  height: 86px;
  overflow: hidden;
  border-radius: 8px;
}

.itemInfo {
  position: relative;
  @include flex-column;
  gap: 4px;
  padding: 8px 8px 8px 0;
  max-width: calc(100% - $imageWidth - $offsetFromImage);
}

.title {
  padding-right: 27px;
}

.address {
  white-space: nowrap;
  color: $grey_caption-disabled-text;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardListPost{
  @include flex-column;
  gap: 16px;
}

.shortOfferWithLike {
  position: relative;
}

.addFavoriteButton {
  position: absolute;
  top: -2px;
  right: 4px;
}

.price {
  color: $black_main-text;

  &.disabled {
    color: $grey_caption-disabled-text;
  }
}
