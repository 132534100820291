@import 'src/styles/color';
@import 'src/styles/mixin';

.main {
  display: grid;
  height: 90px;
  padding: 12px 16px 12px 12px;
  cursor: pointer;
  grid-template-columns: auto 1fr auto;

  &:hover {
    background-color: $blue_main-100;
  }
}

.selected {
  background-color: $blue_main-100;
}

.avatar {
  margin-right: 12px;
}

.info {
  @include flex-column;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user {
  @include flex-row;

  padding-bottom: 4px;
  gap: 4px;
}

.partner {
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
}

.offer {
  overflow: hidden;
  padding-bottom: 5px;
  font-weight: bold;
  text-overflow: ellipsis;
}

.message {
  overflow: hidden;
  color: $grey_caption-disabled-text;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date {
  @include flex-column;

  align-items: flex-end;
  margin-left: auto;
  color: $grey_caption-disabled-text;
}

.counter {
  @include flex-row-center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: auto;
  background-color: $blue_main-500;
  color: $white_BG;
}
