@import '../../../styles/color';
@import '../../../styles/mixin';
@import '../../../styles/typography';

.phoneField {
  @include flex-row;

  position: relative;
}

.codeDropdown {
  position: absolute;
  width: 112px;
  height: 100%;

  @include flex-row;

  align-items: center;
  padding-left: 12px;
}

.codeDropdownButton {
  border: none;
}

.codeButtonIcon {
  align-self: center;
}

.phoneBodyInput {
  padding-left: 118px;
}