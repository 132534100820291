@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

.title {
  margin: 0;
}

.heading {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 12px;
  margin-bottom: 24px;
  align-items: center;
}

.filterSidebarForm {
  margin-bottom: $STICKY_BUTTON_SPACE;
}

.field {
  @include bodySizeM;
  color: $black_main-text;

  @include flex-column;
  gap: 20px;
}

.fieldTitle {
  @include flex-row;
  gap: 4px;
}

.filterPanel {
  @include flex-column;
  gap: 32px;
  padding-bottom: 24px;
}

.subfields {
  @include flex-column;
  gap: 12px;
}

.optionsBlock, .optionsBlockTwoCol {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  justify-items: start;
  justify-content: start;
}

.priceField {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.inputPlus {
  position: relative;

  & input {
    padding-right: 36px;
  }

  & button {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.searchWithChips {
  @include flex-column;
  gap: 12px;
}

.chips {
  @include flex-row;
  flex-wrap: wrap;
  gap: 12px 8px;
}

.summary {
  margin-top: 28px;
  @include flex-column;
  gap: 12px;
  align-items: flex-start;
}

.breadcrumbs {
  margin: 16px 0;
}

.fullSizeFilter {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $white_BG;
  min-height: 100vh;
  width: 100%;
  z-index: $Z-IND-MODAL-CONTENT;
  padding: 0 $BASE_PADDING;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .optionsBlockTwoCol {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    padding: 0;
    margin-bottom: 0;
  }

  .filterPanel {
    padding-bottom: 0;
  }
}
