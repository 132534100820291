@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants';

.infoCard{
  color: $grey_secondary-text;
}

.innerInfo{
  margin-top: 16px;
}

.registrationDate{
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
}

.answerTime{
  display: flex;
  padding-bottom: 12px;
  gap: 4px;
}

.avatarCard{
  @include flex-row-center;

  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 12px;

}

.userNameWithGrade{
  @include flex-column;

  gap: 8px;
}

.badgesContainer {
  margin-top: 8px;
  margin-bottom: 16px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .avatarCard{
    @include flex-column;

    align-items: flex-start;
    gap: 12px;
  }

  .avatar {
    width: 120px;
    height: 120px;
    margin-bottom: 12px;
  }
}
