@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

$OFFER_CARD_GAP: 12px;
$OFFER_CARD_GAP_DESKTOP: 16px;
$OFFER_CARD_HEIGHT: 188px;
$OFFER_CARD_IMAGE_WIDTH: 140px;
$imageHeight: 178px;
$userInfoWidth: 246px;
$itemHorizontalHeartPositionLeft: 250px;
$itemHorizontalHeartPositionLeftMobile: 100px;
$OFFER_CARD_IMAGE_WIDTH_DESKTOP: 294px;

.main {
  margin-top: 20px;
}

.item {
  @include flex-column;

  position: relative;
  max-width: 100%;

  &::before {
    position: absolute;
    width: calc(100% + ($OFFER_CARD_GAP * 2));
    height: calc(100% + $OFFER_CARD_GAP);
    border-radius: 12px;
    margin: -$OFFER_CARD_GAP;
    content: "";
  }

  .addFavoriteButton {
    position: absolute;
    z-index: $Z-IND-ONTOP;
    top: 4px;
    right: 4px;
    width: fit-content;
  }

  .photo {
    @include flex-row-center;

    overflow: hidden;
    height: $imageHeight;
    border-radius: 8px;
    background-color: $white_BG;

    img {
      object-position: center;
    }
  }

  &:hover {
    &::before {
      background-color: $grey_main-BG-stroke;
    }
  }
}

.itemHorizontal {
  width: 100%;
  height: $OFFER_CARD_HEIGHT;

  &::before {
    height: calc(100% + ($OFFER_CARD_GAP * 2));
  }

  .addFavoriteButton {
    left: $itemHorizontalHeartPositionLeftMobile;
  }
}

.offerCardLink {
  display: grid;
  width: 100%;
  border-radius: 8px;
  background-color: $white_BG;
  gap: $OFFER_CARD_GAP;
  grid-template-columns: $OFFER_CARD_IMAGE_WIDTH auto;

  .photo {
    width: $OFFER_CARD_IMAGE_WIDTH;
    height: $OFFER_CARD_HEIGHT;

    img {
      height: $OFFER_CARD_HEIGHT;
      object-fit: cover;
    }
  }
}

.imageWithHeart{
  position: relative;
  background-color: transparent;
}

.items {
  display: grid;
  gap: 20px $OFFER_CARD_GAP;
  grid-template-columns: 1fr;
}

.items.itemsHorizontal {
  grid-template-columns: 1fr;
}

.userInfo {
  position: absolute;
  right: 0;
  width: $userInfoWidth;
  padding: 16px 0;
  padding-right: 20px;
  margin-left: auto;
}

@media screen and (min-width: $XS-MOBILE-BREAKPOINT) {
  .items {
    grid-template-columns: repeat(2, calc((100% - $OFFER_CARD_GAP) / 2));
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .itemHorizontal {
    .addFavoriteButton {
      right: unset;
      left: $itemHorizontalHeartPositionLeft;
    }
  }

  .offerCardLink {
    display: grid;
    width: 100%;
    gap: $OFFER_CARD_GAP_DESKTOP;
    grid-template-columns: $OFFER_CARD_IMAGE_WIDTH_DESKTOP auto $userInfoWidth;

    .photo {
      width: $OFFER_CARD_IMAGE_WIDTH_DESKTOP;
      height: 100%;
    }
  }

  .items {
    gap: 24px $OFFER_CARD_GAP_DESKTOP;

    /*
     * There are 4 columns and 3 gaps between them.
     * To get the fixed width for one column we need
     * to subtract all the gaps from the full width and divide by 4
     */
    grid-template-columns: repeat(4, calc((100% - $OFFER_CARD_GAP_DESKTOP * 3) / 4));
  }
}
