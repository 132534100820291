body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, embed {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
}

th,
td {
  vertical-align: top;
}

ol,
ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
