@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.container {
  @include flex-column;
  gap: 24px;
}

.noOffersContainer {
  @include flex-column;
  gap: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .container{
    display: grid;
    grid-template-columns: 3fr 9fr;
    margin-top: 60px;
    gap: 40px;  
  }
}
