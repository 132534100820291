@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.main {
  background-color: $grey_disabled-BG;
  border-radius: 12px;
  @include flex-column;
  gap: 12px;
  padding: 12px 20px 16px;
}

.list {
  @include flex-row;
  flex-wrap: wrap;
  row-gap: 4px;

  color: $grey_caption-disabled-text;

  li {
    display: inline-block;
  }
  li:not(:last-child)::after {
    content: '•';
    margin: 0 9px;
    letter-spacing: normal;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    @include flex-row-center;
    justify-content: space-between;
    padding: 12px 20px;
    margin-top: 12px;
  
    button {
      align-items: center;
    }
  }
}