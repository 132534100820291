@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/typography.scss';

.tabbarButton {
  @include flex-column-center;
  gap: 4px;
  color: $grey_caption-disabled-text;

  &.active {
    color: $blue_main-500;
  }
}

.icon {
  position: relative;

  &.hasNotification::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $red_error-500;
    top: -4px;
    right: -8px;
  }

}