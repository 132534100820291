@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

$ONLINE_STATUS_COLOR: #38DB48;

.onlineStatus{
  @include flex-row-center;
  gap: 8px;
  align-self: center;
}

.onlineIcon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $ONLINE_STATUS_COLOR;
}

.onlineLabel{
  color: $grey_caption-disabled-text;
  user-select: none;
}