@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';

.main {
  @include flex-row-center;
  width: 100%;
  height: 100%;
  background-color: $placeholder-background;

  img {
    object-fit: contain !important;
  }
}
