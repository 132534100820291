@import 'src/styles/color';
@import 'src/styles/constants';

.avatar {
  width: $HEADER_USER_AVATAR_SIZE;
  height: $HEADER_USER_AVATAR_SIZE;
  font-size: 16px;
  line-height: normal;
  background-color: $blue_main-500;
}

.userName {
  color: $white_BG;
}