@import 'src/styles/mixin';

.double{
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}

.single{
  display: grid;
  grid-column: span 1;
  grid-template-columns: repeat(1, 1fr);

  @include flex-column;
}

.info {
  margin-bottom: 22px;
}

.list{
  row-gap: 12px;
}