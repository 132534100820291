@import '../../styles/color.scss';

.main {
  padding: 0;

  &:hover {
    svg {
      path {
        fill: $black_main-text;
      }
    }
  }
}