@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.map {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin: auto;

  &.centerPointer {
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 44px;
      height: 50px;
      margin: -40px 0 0 -20px;
      background: url('../../../public/map/marker.svg');
      background-size: 44px 50px;
      content: ' ';
      pointer-events: none;
    }

    &.markerError::after {
      background: url('../../../public/map/marker-error.svg');
    }
  }
}

.container {
  @include flex-column;

  height: 100%;
  gap: 24px;
}

.autocomletes {
  @include pop-up-menu;

  z-index: $Z-IND-DROPDOWN;
  top: 44px;
  width: 100%;
  padding: 8px 0;
  border: 1px solid $grey_main-BG-stroke;
  border-radius: 8px;

  @include box-shadow($black_main-text);
}

.divider {
  display: block;
  height: 1px;
  margin: 4px 16px;
  background-color: $grey_main-BG-stroke;
}

.autocomlete {
  width: 100%;

  .item {
    display: flex;
    width: 100%;
    padding: 8px 14px;
    cursor: pointer;

    &:hover {
      background-color: $grey_main-BG-stroke;
    }
  }

  .primary {
    color: $black_main-text;
  }

  .secondary {
    color: $grey_caption-disabled-text;
  }

  &:last-child {
    .divider {
      display: none;
    }
  }
}

.subtitle {
  color: $black_main-text;
  line-height: 40px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .map {
    min-height: 300px;
  }
}

.icon {
  margin-right: 8px;
}
