@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants.scss';

$roundedShadow: 0px 4px 4px 0px rgba($shadow-color-1, 0.12),
  0px -1px 4px 0px rgba($shadow-color-2, 0.12);

.main {
  @include flex-row-center;

  width: 36px;
  height: 36px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: $white_BG;
  box-shadow: $roundedShadow;
  @include svg-stroke-current-color();

  &.active:hover {
    background-color: $white_BG;
  }
}

.heart {
  @include flex-row-center;

  &:hover {
    color: $purple-hover;
  }

  &.active {
    color: $blue_main-500;
    @include svg-fill-current-color();

    &:hover {
      color: $purple-hover;
      @include svg-fill-stroke-current-color();
    }
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {  
    width: 40px;
    height: 40px;
  }
}
