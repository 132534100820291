@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

$CARD_HEIGHT: 360px;
$IMAGE_WIDTH: 485px;

.list {
  @include flex-column;
  row-gap: 32px;
  margin: 24px 0;


  .listItem {
    @include flex-column;
    row-gap: 8px;
    overflow: hidden;
    height: fit-content;

    .content {
      @include flex-column;
      row-gap: 8px;

      & .cardSubtitle {
        color: $grey_caption-disabled-text;
      }
    }

    .img {
      margin-bottom: -20px;
      margin-right: -20px;
      margin-left: -20px;
      object-fit: contain;
    }
  }
}

.main {
  .subtitle {
    margin-top: 20px;
    color: $grey_secondary-text;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .list {
    .listItem {
      @include flex-row;
      column-gap: 24px;
      max-height: $CARD_HEIGHT;
      padding: 0;

      .content {
        padding: 32px;
        flex: 0 0 50%;
      }
      .img {
        flex: 0 0 50%;
        margin: 0;
        max-width: $IMAGE_WIDTH;
      }

      &:nth-child(2) {
        flex-direction: row-reverse;
      }
    }
  }

  .btn {
    margin-bottom: 60px;
  }
}