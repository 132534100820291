@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/constants';

.container {
  @include flex-row;

  position: sticky;
  z-index: $Z-IND-STICKY;
  top: 0;
  justify-content: space-between;
  padding: 12px 0;
  background-color: $grey_disabled-BG;
  gap: 12px;
}

.titleBlock {
  @include flex-row;

  gap: 12px;
}

.iconsRow {
  @include flex-row-center;

  gap: 12px;
}

.dropdownFullWidth {
  width: min(calc(100vw - $BASE_PADDING * 2), $MOBILE-BREAKPOINT);
}

.dropdownMenu {
  min-width: 258px;
}