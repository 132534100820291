@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

.overlayCookie {
  max-width: $MOBILE_BASE_WIDTH;
  z-index: $Z-IND-ONTOP;
}

.cookieContainer {
  @include flex-column;
  gap: 20px;
}

.cookieText{
  @include bodySizeM();
  color: $black_main-text;
}

.cookieBtnContainer {
  @include flex-column;
  gap: 12px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .cookieContainer {
    position: relative;
    padding: 48px 28px 32px 28px;
    border-radius: 16px;
    @include box-shadow($shadow-color-1);
    background: $white_BG;
  }

  .overlayCookie {
    position: fixed;
    left: 40px;
    bottom: 40px;
  }

  .cookieBtnContainer {
    @include flex-row-center;
    gap: 16px;

    button {
      width: 100%;
    }
  }
}
