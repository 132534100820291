@import '../../styles/color.scss';

.main {
  position: relative;

  &.hasNotification::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $red_error-500;
    top: -4px;
    right: -8px;
  }

}
