@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.cardStackList{
  @include flex-column;
  position: sticky;
  top: $HEADER_HEIGHT + $SEARCHBAR_HEIGHT;
  gap: 34px;
}

.cardStackMain{
  @include flex-column;
}

.moreButton{
  padding-top: 18px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .cardStackLabel {
    padding-bottom: 16px;
  }
}