@import 'src/styles/mixin.scss';
@import 'src/styles/static.scss';

$BUTTON_WIDTH: 374px;

.header {
  @include flex-column;
  row-gap: 16px;
}

.btn {
  margin-top: 8px;
}

@media (min-width: $DESKTOP-BREAKPOINT) {
  .btn {
    width: $BUTTON_WIDTH;
  }
}