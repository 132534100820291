@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/mixin.scss';

.components {
  @include flex-column;
  row-gap: 44px;
}

.subheading {
  @extend .bodySizeL;
  margin-bottom: 44px;
}

.h2title {
  margin-bottom: 24px;
  @include decorative-bold-sizeS;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .components {
    row-gap: 120px;
  }

  .container {
    grid-column: 1 / 13;
  }  

  .subheading {
    @include decorative-regular-sizeL();
    margin-bottom: 100px;
  }

  .h2title {
    @include decorative-bold-sizeM();
    margin-bottom: 60px;
  }
}

