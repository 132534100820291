@import 'src/styles/mixin';
@import 'src/styles/color';

.stickyInfoPanel {
  @include flex-column;

  padding: 16px 16px 24px;
  gap: 28px;
}

.stickyTitle{
  @include text-overflow-ellipsis(1);

  display: block;
  padding-bottom: 8px;
}