@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

.breadcrumbs {
  @include flex-row;
  flex-wrap: wrap;
  position: relative;
  gap: 4px;
  width: 100%;
  margin-bottom: 16px;
}

.tagWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.separator {
  color: $grey_secondary-text;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .breadcrumbs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
  }

  .breadcrumbsOverflow::after {
    position: absolute;
    right: 0;
    padding: 2px;
    background-color: $grey_disabled-BG;
    content: '...';
  }
}
