@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/typography';
@import 'src/styles/responsive';

.modalContainer {
  width: 100%;
  height: 100%;

  @include mobile-paddings;
}

.formRadio {
  @include bodySizeM;

  color: $black_main-text;
  gap: 20px;
}

.buttonRow {
  @include flex-column;

  gap: 12px;
}

.successContainer {
  width: 100%;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .modalContainer {
    width: 472px;
    padding: 0;
  }

  .successContainer {
    width: 366px;
  }
}
