$purple_hover: #7857FC;
$purple_visited: #9E69FC;
$grey_secondary-text: #595959;
$black_main-text: #2c2c2c;
$black_main-text-50: rgba($black_main-text, 0.5);
$black_main-text-20: rgba($black_main-text, 0.2);
$black_main-text-08: rgba($black_main-text, 0.08);
$red_error-500: #FF5656;
$red_error-700: #F1484B;
$grey_caption-disabled-text: #767676;
$grey_main-BG-stroke: #F0F0F0;
$blue-main: #6A7EFC;
$grey_focused-stroke-scroll-bar: #D1D2D5;
$blue_main-500: #6A7EFC;
$blue_main-400: #96A5FF;
$blue_main-300: #AFBAFF;
$blue_main-100: #ECEEFF;
$blue_main-50: #F5F7FF;
$white_BG: #FFF;
$white_BG_80: rgba($white_BG, 0.8);
$grey_disabled-BG: #FAFAFA;
$gray-dark-light: #F5F5F5;
$green_success-100: #E5F9E7;
$orange_rating-300: #FFDAAB;
$orange_rating-500: #FFC173;
$green_success-500: #00C414;
$green_success-400: #4DDA84;
$orange_rating-100: #FFF1DF;
$red_error-100: #FFE6E6;
$red_error-300: #FF9A9A;
$transparent-hovers-Dark-on-dark: rgba(#FFFFFF1A, .1);
$transparent-hovers-light-on-dark: rgba(#FFF, .7);
$transparent-hovers-on-white: rgba($black_main-text, .08);
$placeholder-background: #E8E8E8;
$Decorative-Lavender: #E5E8FF;
$Decorative-Mint: #D0F4DE;
$Decorative-Banana: #FCF6BD;
$Decorative-Kiwi: #E5FDBE;

$shadow-color-1: #636363;
$shadow-color-2: #534e5f;
$shadow-color-3: #d8d8d8;
