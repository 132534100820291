@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.main {
  height: 100%;
}

.powerItemNumber {
  vertical-align: super;
  font-size: 12px;
}

.tabItems {
  @include flex-row;
  gap: 40px;
  overflow-x: auto;
  position: relative;

  @include hide-scrollbar;
}

.nav {
  margin-top: 24px;
  margin-bottom: 16px;
  position: relative;

  .hr {
    height: 1px;
    width: 100%;
    background-color: $grey_main-BG-stroke;
    position: absolute;
    bottom: 1.5px;
    z-index: $Z-IND-BACKGROUND;
  }

  h3 {
    display:inline-block;
  }

  a {
    &:hover {
      color: $purple_hover;
    }
  }
}

.tabName {
  @include flex-row;
  white-space: nowrap;
}

.tabItem {
  position: relative;
}

.tabLink {
  @include flex-row;
  position: relative;
  column-gap: 6px;
  color: $black_main-text;
}

.selected {
  position: relative;
  padding-bottom: 14px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: $black_main-text;
    border-radius: 3px;
  }

  &:hover {
    &::after {
      background-color: $purple_hover;
    }
  
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .nav {
    margin-top: 36px;
  }

  .hr {
    margin-top: 14px;
  }
}
