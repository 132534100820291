@import '../../../styles/color.scss';
@import '../../../styles/mixin.scss';

.iconLink {
  svg {
    path {
      stroke: $white_BG;
    }
  }
}

.main {
  @include flex-row;
  column-gap: 12px;
  margin: 0 48px 0 16px;
}
