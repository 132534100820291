@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

$RIGHT_BORDER_WIDTH: 2px;

.main {
  background-color: $grey_focused-stroke-scroll-bar;
  width: 100%;
  height: 8px;
  border-radius: 99px;
  margin-top: 12px;
  overflow: hidden;
}

.inner {
  background-color: $grey_secondary-text;
  height: 100%;
  border-radius: 99px 0px 0px 99px;
  border-right: 2px solid $white_BG;
}

.innerWrapper {
  width: calc(100% + $RIGHT_BORDER_WIDTH);
  height: 100%;
  overflow: hidden;
  border-radius: 99px;
}

.caption {
  color: $grey_caption-disabled-text;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .caption {
    color: $black_main-text;
  }
}
  