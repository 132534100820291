@import 'src/styles/static.scss';
@import 'src/styles/mixin.scss';

.sectionTitle {
  margin-top: 60px;
}

.headerImage {
  width: 100%;
  margin-top: 24px;
}

.stats {
  & .statsList {
    margin-bottom: 0;
  }
}

.features {
  @include flex-column;
  row-gap: 16px;
  margin-top: 24px;

  & .feature {
    @include flex-column;
    row-gap: 8px;
  }
}

.featuresBtn {
  margin-top: 24px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .sectionTitle {
    margin-top: 120px;
  }
  .stats {
    & .statsList {
    margin-top: 36px;
    }
  }

  .features {
    margin-top: 44px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto auto;
    grid-gap: 24px;

    & .feature {
      grid-column: span 2;
      padding: 24px;
      border-radius: 20px;

      &:nth-child(4) {
        grid-column: 1 / span 3;
      }

      &:nth-child(5) {
        grid-column: 4 / span 3;
      }
    }
  }
}
