@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';

.main {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 50px;
  background-color: $white_BG;
  border-radius: 16px;
}

.content{
  overflow: hidden;
  word-wrap: break-word;
}

.sidebar{
  position: sticky;
  height: fit-content;
  top: 0;
  box-sizing: border-box;
}

.rightPart {
  grid-column: 8 / span 3;
  margin-right: 65px;
}
