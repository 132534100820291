@import './color';

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row-center {
  @include flex-row;
  @include flex-center;
}

@mixin flex-column-center {
  @include flex-column;
  @include flex-center;
}

.flex-row {
  @include flex-row
}

.flex-column {
  @include flex-column
}

.flex-row-center {
  @include flex-row-center
}

.flex-column-center {
  @include flex-column-center
}

@mixin icon {
  display: block;
  overflow: hidden;
  padding: 0;
  border: none;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 0;
  opacity: .5;
  text-indent: 9999px;
  transition: opacity .3s ease;

  &:hover {
    opacity: 1;
  }
}

@mixin button {
  @include flex-row-center;

  border: none;
  border-radius: 8px;
  background-color: $blue-main;
  text-align: center;

  &,
  &:hover {
    color: $white_BG;
  }

  &:hover {
    background-color: $purple_hover;
  }

  &:disabled {
    background-color: $grey_caption-disabled-text;
    cursor: default;
  }
}

@mixin inner {
  position: relative;
  width: 100vw;
  max-width: $MOBILE_BASE_WIDTH;

  @media screen and (min-width: $DESKTOP-BREAKPOINT) {
    width: $BASE_WIDTH;
    max-width: 100%;
    padding: 0 $BASE_PADDING;
  }
}

@mixin inner-static {
  width: $BASE_WIDTH_STATIC;
  max-width: $BASE_WIDTH_STATIC;
  margin: 0 auto;
}

@mixin pop-up-menu {
  position: absolute;
  top:0;
  left: 0;
  border-radius: 3px;
  background-color: $white_BG;
  box-shadow: 0 6px 18px $black_main-text-20;
  transition: opacity .2s ease;
}

@mixin button-icon {
  border-radius: 50%;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-color: $gray-light;
  }
}

@mixin transition($properties: all, $duration: 0.3s, $timing-function: ease, $delay: 0s) {
  transition: $properties $duration $timing-function $delay;
}

@keyframes rotation {
  from {
     transform: rotate(0deg);
  }

  to {
     transform: rotate(360deg);
  }
}

@mixin rotate($time: 1s) {
  animation: rotation $time linear infinite;
}

@mixin svg-fill-current-color {
  svg {
    path {
      fill: currentcolor;
    }
  }
}

@mixin svg-stroke-current-color {
  svg {
    path {
      stroke: currentcolor;
    }
  }
}

@mixin svg-fill-stroke-current-color {
  @include svg-fill-current-color;
  @include svg-stroke-current-color;
}

@mixin hide-scrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin box-shadow($color) {
  box-shadow: 0 2px 6px -2px rgba($color, 0.03), 0 4px 24px -4px rgba($color, 0.08);
}

@mixin text-overflow-break-word {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

@mixin text-overflow-ellipsis($linesNum) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $linesNum;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
