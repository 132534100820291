@import 'src/styles/color';
@import 'src/styles/constants';

.main {
  position: relative;
  background-color: $white_BG;
}

.form {
  bottom: 4px;
  padding: $MESSENGER_MESSAGE_GROUP_PADDING;
  box-shadow: 0px -2px 16px 0px  rgba($shadow-color-1, 0.08);
}