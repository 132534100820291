@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/responsive.scss';

.baseContainer {
  @include flex-column-center;
}

.base {
  @include inner;
  @include mobile-paddings;

  height: 100%;
  padding-top: 0;
  margin-bottom: 48px;
}

.content {
  margin-top: 48px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .base {
    margin-bottom: 80px;
    padding: 0;

    &.smallFooter {
      margin-bottom: 40px;
    }
  }
}
