@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/constants';

.userInfoBlock {
  @include flex-column;

  gap: 4px;
}

.badges {
  margin-top: 4px;
}

.name {
  @include text-overflow-ellipsis(1);

  display: block;
  margin-top: 4px;
}

.shortUserInfo {
  @include flex-column;

  gap: 4px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .shortUserInfo {
    .registrationDate {
      @include flex-row;

      color: $grey_secondary-text;
      gap: 4px;
    }
  }
}
