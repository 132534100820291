@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';

.formContainer {
  @include flex-column;
  gap: 16px;

  & input {
    background-color: $grey_main-BG-stroke;
  }
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .formContainer {
    width: 278px;
    & input {
      background-color: $white_BG;
    }
  }
}
