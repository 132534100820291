@import 'src/styles/mixin.scss';

.fileInput {
  display: none;
}

.filesWrapper {
  @include flex-row;
  gap: 16px;
  flex-wrap: wrap;
}
.uploadedPhoto {
  margin-top: 20px;
}

.error {
  margin-top: 22px;
}
