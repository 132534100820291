@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';

.authModalContainer {
  width: 100%;
  height: 100%;

  .inlineButton {
    display: inline-flex;
   }
}

.formContainter {
  height: 100%;
}

.text {
  color: $grey_caption-disabled-text;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: $purple_hover;
    }
  }
}

.formContainer {
  @include flex-column;
  gap: 20px;
}

.secondaryText {
  color: $grey_secondary-text !important;
}

.footer {
  @include flex-column-center;
  padding-top: 24px;
  gap: 16px;
  width: 100%;
}

.footerReset {
  p {
    align-self: center;
  }
  @include flex-column;
  gap: 16px;
  padding-top: 24px;
}

.toLogin {
  p {
    color: $grey_secondary-text;
    align-self: center;
  }

  @include flex-column;
  gap: 16px;
  width: 100%;
}

.passwordBlock {
  @include flex-column;
  gap: 12px;
}

.messageSentEmail {
 @include bodySizeM;

 color: $grey_secondary-text;
}

.largeButton {
  font-size: 18px;
}

.buttonFullWidth {
  width: inherit;
}

.alignRight {
  align-self: flex-end;
}

.user {
  @include flex-row;
  gap: 12px;
  align-items: center;
}

.buttonRow {
  @include flex-row-center;
  gap: 16px;
}

.smthWentWrongContainer {
  width: 448px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .authModalContainer {
    width: 366px;
  }

  .toLogin {
    @include flex-row;
    gap: 8px;
  }

  .footerReset {
    gap: 0;
    align-items: center;
  }
}
