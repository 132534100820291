@import 'src/styles/mixin';
@import 'src/styles/color';


.badgeWrapped {
  @include flex-column;

  padding-bottom: 5px;
  gap: 10px;

  &.small {
    flex-flow: row wrap;
    gap: 4px;
  }
}

.showMoreBadges{
  margin-right: auto;
}
