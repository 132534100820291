@import './color';
@import './constants';

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Inter/static/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Inter/static/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype');
}

$font-family-inter: 'Inter', sans-serif;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-28: 28px;

.body-font-style {
  font-family: $font-family-inter;
}

.body-text {
  color: $black_main-text;
  font-family: $font-family-inter;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.heading {
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.mobile-h1 {
  font-size: 46px;
}

.mobile-h2 {
  font-size: 36px;
}

.mobile-h3 {
  font-size: 32px;
  line-height: 110%;
}

.mobile-h4 {
  font-size: 26px;
  line-height: 110%;
}

.mobile-h5 {
  font-size: 22px;
}

.mobile-h6 {
  font-size: 18px;
  line-height: 110%;
}

.mobile-card-heading {
  font-family: $font-family-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}

.mobile-heading-subtitle {
  font-feature-settings: 'calt' off;
  font-size: 16px;
  line-height: 140%;
}

@mixin body-text {
  font-family: $font-family-inter;
}

@mixin label {
  @include body-text;

  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}

.label-12 {
  @include label;

  font-size: $font-size-12;
}

@mixin label-14 {
  @include label;

  font-size: $font-size-14;
}

.label-14 {
  @include label-14;
}

@mixin label-16 {
  @include label;

  font-size: $font-size-16;
  line-height: 150%;
}

.label-16 {
  @include label-16;
}

.label-18 {
  @include label;

  font-size: $font-size-18;
  line-height: 150%;
}

.label-20 {
  @include label;

  font-size: $font-size-20;
}

.label-24 {
  @include label;

  font-size: $font-size-24;
  line-height: 140%;
}

.label-28 {
  @include label;

  font-size: $font-size-28;
  line-height: 140%;
}

@mixin caption {
  @include body-text;

  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.caption-14 {
  @include caption;

  font-size: $font-size-14;
}

.caption-12 {
  @include caption;

  font-size: $font-size-12;
}

.supportive-14 {
  @include caption;

  font-size: $font-size-14;
}

.supportive-12 {
  @include caption;

  font-size: $font-size-12;
}

@mixin bodySizeS {
  font-family: $font-family-inter;
  font-size: $font-size-14;
  font-weight: 400;
  line-height: 135%;
}

.bodySizeS {
  @include bodySizeS
}

@mixin bodySizeM {
  color: $grey_secondary-text;
  font-family: $font-family-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.bodySizeM {
  @include bodySizeM
}

@mixin bodySizeL {
  color: $black_main-text;
  font-family: $font-family-inter;
  font-size: $font-size-18;
  font-style: normal;
  font-weight: 400;
  line-height: 165%;
}

@mixin desktopBody() {
  color: $black_main-text;
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: 400;
}

@mixin desktopBodySize16 {
  @include desktopBody;

  font-size: 16px;
  line-height: 150%;
}

@mixin desktopBodySize22 {
  @include desktopBody;

  font-size: 22px;
  line-height: 150%;
}

@mixin desktopBodySize28 {
  @include desktopBody;

  font-size: 28px;
  line-height: 140%;
}

@mixin desktopBodySize32 {
  @include desktopBody;

  font-size: 32px;
  line-height: 140%;
}

.body-text.desktop-body-32 {
  @media (min-width: $DESKTOP-BREAKPOINT) {
    font-size: 32px;
  }
}

.body-text.desktop-body-28 {
  @media (min-width: $DESKTOP-BREAKPOINT) {
    font-size: 28px;
  }
}

.body-text.desktop-body-22 {
  @media (min-width: $DESKTOP-BREAKPOINT) {
    font-size: 22px;
    line-height: 150%;
  }
}

.body-text.desktop-body-16 {
  @media (min-width: $DESKTOP-BREAKPOINT) {
    font-size: 16px;
    line-height: 150%;
  }
}

.mobile-body-16 {
  color: $grey_secondary-text;
  font-family: $font-family-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.bodySizeL {
  @include bodySizeL;
}

@mixin heading {
  padding: 0 12px 0 16px;
}

.typography-price {
  font-family: $font-family-inter;
  font-size: 40px;
  font-weight: 600;
  line-height: 110%;
}

.underline{
  text-decoration: underline;
}

@mixin decorative-bold {
  color: $black_main-text;
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: bold;
}

@mixin decorative-regular {
  color: $black_main-text;
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
}

@mixin decorative-medium {
  color: $black_main-text;
  font-family: $font-family-inter;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@mixin decorative-medium-sizeL {
  @include decorative-medium;

  font-size: 28px;
}

@mixin decorative-medium-sizeXL {
  @include decorative-medium;

  font-size: 46px;
}

@mixin decorative-bold-sizeS {
  @include decorative-bold;

  font-size: 32px;
  line-height: 36px;
}

@mixin decorative-bold-sizeM {
  @include decorative-bold;

  font-size: 46px;
  line-height: 140%;
}

@mixin decorative-bold-sizeL {
  @include decorative-bold;

  font-size: 62px;
  line-height: 110%;
}

@mixin decorative-regular-sizeS {
  @include decorative-regular;

  font-size: 22px;
  line-height: 150%;
}

@mixin decorative-regular-sizeL {
  @include decorative-regular;

  font-size: 32px;
}

@mixin decorative-regular-sizeXL {
  @include decorative-regular;

  font-size: 40px;
  line-height: 130%;
}

@mixin decorative-regular-sizeM {
  @include decorative-regular;

  font-size: 28px;
}

@media (min-width: $DESKTOP-BREAKPOINT) {
  .heading.desktop-h4 {
    font-size: 32px;
    line-height: 110%;
  }

  .heading.desktop-h1 {
    font-size: 62px;
    line-height: 110%;
  }

  .heading.desktop-h2 {
    font-size: 46px;
    line-height: 140%;
  }

  .heading.desktop-h5 {
    font-size: 26px;
    line-height: 110%;
  }

  .heading.desktop-h6 {
    font-size: 18px;
    line-height: 110%;
  }

  .heading.desktop-h7 {
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 22px;
  }

  .desktop-label-18 {
    @include label;

    font-size: $font-size-18;
    line-height: 150%;
  }

  .desktop-body-18 {
    font-size: 18px;
    line-height: 165%;
  }
}