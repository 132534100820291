@import 'src/styles/typography.scss';
@import 'src/styles/constants.scss';
@import 'src/styles/mixin.scss';

.text {
  @include decorative-regular-sizeM();
}

.subheading {
  @extend .bodySizeL;

  color: $blue_main-500;
  border: 1px solid $blue_main-500;
  padding: 7px 12px;
  border-radius: 99px;
  width: fit-content;
}

.points {
  @include flex-column;
  @extend .bodySizeL;

  gap: 24px;
  color: $grey_secondary-text;
  margin-top: 16px;
}

.link {
  color: $blue_main-500;
}

.pointsBox {
  margin-top: 32px;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .main {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    margin-bottom: 60px;
  }

  .text {
    @include decorative-regular-sizeL();

    grid-column: 1 / 11;
  }

  .pointsBox {
    margin-top: 60px;
    grid-column: 2 / 11;
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    &.en {
      grid-column: 4 / 11;
    }
  }

  .subheading {
    @include flex-row-center;

    grid-column:  1 / 3;
    height: fit-content;
    width: unset;
    margin-right: 40px;
  }

  .points {
    grid-column: 3 / 9;
    margin: 0;
  }
}
