@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/constants.scss';

.mobileMapContainer {
  position: relative;
  @include flex-column;
  height: 100%;
  gap: 16px;
}

.mobileMapAdress {
  @include flex-column;
  gap: 12px;
}

.mobileMapWrapper {
  height: 100%;
  width: calc(100% + 2 * $BASE_PADDING);
  margin-left: -$BASE_PADDING;
}
