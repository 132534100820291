@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.scrollableChatListContent {
  max-height: calc(100dvh - $MESSENGER_PADDING_TOP - $MESSENGER_PADDING_BOTTOM - $MOBILE_TOP_PADDING + 10px);
  overflow-y: auto;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .scrollableChatListContent {
    max-height: calc($MESSENGER_WINDOW_HEIGHT - 72px)
  }
}
